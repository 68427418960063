import { createTypes, createAction } from "lib/action";

const LOAD_TRANSFER_MOLD = createTypes("loadTransferMold", "load");
const TRANSFER_CONCRETE_MOLD = createTypes("transferConcreteMold", "create");

const loadTransferMold = {
  request: (params, token) => createAction(LOAD_TRANSFER_MOLD.REQUEST, { params, token }),
  success: data => createAction(LOAD_TRANSFER_MOLD.SUCCESS, { data }),
  failure: err => createAction(LOAD_TRANSFER_MOLD.FAILURE, { err }),
};

const transferConcreteMold = {
  request: (params, token) => createAction(TRANSFER_CONCRETE_MOLD.REQUEST, { params, token }),
  success: data => createAction(TRANSFER_CONCRETE_MOLD.SUCCESS, { data }),
  failure: err => createAction(TRANSFER_CONCRETE_MOLD.FAILURE, { err }),
};

export {
  loadTransferMold,
  transferConcreteMold,
  LOAD_TRANSFER_MOLD,
  TRANSFER_CONCRETE_MOLD,
};
