import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

import * as moment from "moment";

function* loadQcDayoff(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post("dayoffqc", params, token);
    yield put(actions.loadQcDayoff.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* showQcDayoff(action) {
  try {
    const { id, token } = action.payload;
    const res = yield fetcher.get(`dayoffqc/${id}`, token);
    yield put(actions.showQcDayoff.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* createQcDayoff(action) {
    try {
      const { params, token } = action.payload;
      const res = yield fetcher.post(`dayoffqc/create`, params, token)
      yield put(actions.createQcDayoff.success(res.data));
    } catch (error) {
      handleError(error)
      yield put(actions.createQcDayoff.failure(getErrorMsg(error)));
    }
  }

function* updateQcDayoff(action) {
  try {
    const { params, token } = action.payload;

    params["date_start_at"] = moment(params["date_start_at"]).format("YYYY-MM-DD HH:mm")
    params["date_end_at"] = moment(params["date_end_at"]).format("YYYY-MM-DD HH:mm")

    const res = yield fetcher.post(`dayoffqc/update`, params, token);
    yield put(actions.updateQcDayoff.success(res.data));
    yield put(push("/qc-data"));
  } catch (error) {
    handleError(error);
    yield put(actions.updateQcDayoff.failure(getErrorMsg(error)));
  }
}

function* deletedQcDayoff(action) {
  try {
    const { id, token } = action.payload;
    yield fetcher.get(`dayoffqc/delete/${id}`, token);
    yield put(actions.deletedQcDayoff.success(id));
  } catch (error) {
    handleError(error);
    yield put(actions.deletedQcDayoff.failure(getErrorMsg(error)));
  }
}

function* importQCDayoffDayoff(action) {
  try {
    const { file, company_id, year, month, token } = action.payload;
    const formData = new FormData();
    formData.append("file", file.originFileObj);
    formData.append("company_id", company_id);
    formData.append("year", year);
    formData.append("month", month);
    const res = yield fetcher.upload(`dayoffqc/import`, formData, token);
    yield put(actions.importQCDayoff.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.importQCDayoff.failure(getErrorMsg(error)));
  }
}

function* downloadQCDayoff(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post(`dayoffqc/exportdayoffqc`, params, token);
    yield put(actions.downloadQCDayoff.success(res.data));
    fetcher.download(res.data.data.replace(/\//g, "."));
  } catch (error) {
    handleError(error);
    yield put(actions.downloadQCDayoff.failure(getErrorMsg(error)));
  }
}

export default function* watchQcDayoff() {
  yield all([
    takeEvery(actions.LOAD_QC_DAYOFF.REQUEST, loadQcDayoff),
    takeEvery(actions.SHOW_QC_DAYOFF.REQUEST, showQcDayoff),
    takeEvery(actions.CREATE_QC_DAYOFF.REQUEST, createQcDayoff),
    takeEvery(actions.UPDATE_QC_DAYOFF.REQUEST, updateQcDayoff),
    takeEvery(actions.DELETE_QC_DAYOFF.REQUEST, deletedQcDayoff),
    takeEvery(actions.IMPORT_QC_DAYOFF.REQUEST, importQCDayoffDayoff),
    takeEvery(actions.DOWNLOAD_QC_DAYOFF.REQUEST, downloadQCDayoff),
  ]);
}
