import { createTypes, createAction } from "lib/action";

const LOAD_PHYSICALCOUNT_MOLD = createTypes("loadPhysicalcountMold", "load");
const loadPhysicalcountMold = {
  request: (params, token) => createAction(LOAD_PHYSICALCOUNT_MOLD.REQUEST, { params, token }),
  success: data => createAction(LOAD_PHYSICALCOUNT_MOLD.SUCCESS, { data }),
  failure: err => createAction(LOAD_PHYSICALCOUNT_MOLD.FAILURE, { err }),
};


export {
  loadPhysicalcountMold,
  LOAD_PHYSICALCOUNT_MOLD,
};
