import { LOAD_QC_TRACKING } from "./actions";

const initialState = {
  lists: [],
  total_rows: 0,
  loading: false,
  msg: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_QC_TRACKING.REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case LOAD_QC_TRACKING.SUCCESS:
      return {
        ...state,
        lists: action.payload.data,
        loading: false,
      };
    case LOAD_QC_TRACKING.FAILURE:
      return {
        ...state,
        msg: action.payload.err,
        loading: false,
      };

    default:
      return state;
  }
};
