import styled from "styled-components";
import { Layout, Menu } from "antd";
const { Sider, Header: H } = Layout;

const StyledImageHeader = styled.h2`
  padding: 15px 0px 0px 22px;
  height: 64px;
  width: 270px;
  line-height: 32px;
`;

const StyledHeader1 = styled.p`
  margin-top: 0;
  margin-bottom: 0em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 150;
  display: block;
  font-size: 3em;
`;


const StyledSearchForm = styled.div`
  margin: 0px 0px 24px 0px;
  padding: 10px 10px 20px 20px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
`;


const GeneralStyledContent = styled(Layout.Content)`
  background: white;
  margin: 5px 5px 5px 5px;
  padding: 20px 20px 10px 20px;
  min-height: ${window.innerHeight - 55}px;
`;


const StyledSider = styled(Sider)`
  padding: 0;
  max-width: 270;
  min-width: 270;
  width: 270;
`;


const StyledLogo = styled.img`
  margin-left: 22%;
  margin-top: 100px;
`;


const StyledHearder = styled(H)`
  background: #FDFDFA !important;
  color: #333333 !important;
  padding: 0px 0px px 0px;
  height: 65px !important;
`;

const MenuHeader = styled(Menu)`
  background: #FDFDFA !important;
  color: white !important;
  line-height: 46px !important;
  border-bottom: none !important;
  text-align: right !important;
`

const LoginBackground = styled.div`
  background: #29313a;
  #title-account-login{
    .ant-card-head-wrapper{
      position: relative;
      z-index : 1;
    }
    .ant-card-head-wrapper:before {
      content : "";
      position: absolute;
      margin: 0 auto;
      left: calc( 50% - 35px );
      bottom  : 0;
      height  : 1px;
      width   : 70px;  
      border-bottom:1px solid #2098e2;
    }
    .ant-input-affix-wrapper{
      border: none;
      border-bottom: solid 1px #000000;
    }
    .ant-input-prefix{
      color: #2098e2;
    }

  }
  /* height: 101%;
  background: rgb(183,248,219);
  background: linear-gradient(27deg, rgba(183,248,219,1) 0%, rgba(80,167,194,0.6640406162464986) 35%, rgba(0,212,255,1) 100%); */
`

export {
  StyledHearder,
  StyledLogo,
  StyledSearchForm,
  StyledHeader1,
  GeneralStyledContent,
  StyledSider,
  StyledImageHeader,
  MenuHeader,
  LoginBackground
};
