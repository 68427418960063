import qcData from "./qc-data/reducer";
import qcDayoff from "./vacation/reducer";
import qcCalendar from "./calendar/reducer";
import qcJob from "./qc-job/reducer";
import qcSupervisor from "./supervisor/reducer";
import qcRoutine from "./qc-routine/reducer";

const qcMangement = {
  qcData,
  qcDayoff,
  qcCalendar,
  qcJob,
  qcSupervisor,
  qcRoutine,
};

export default qcMangement;
