import React, { Component, Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { Layout as AppLayout } from "antd";
import LangContext, { i18n } from "modules/shared/context/langContext";
import PrivateRoute from "./PrivateRoute";
import Header from "./Header";

import * as authActions from "modules/auth/actions";
import * as uiActions from "modules/ui/actions";

import Login from "modules/auth/components/Login";

import { ListMenu } from "./ListMenu";
import ContentLayout from "./ContentLayout";

class Layout extends Component {
  state = {
    i18n: i18n.th,
    langBtn: "th",
    checkSelectLang: "",
    url: "",
  };

  changeLang = (inputLng) => {
    this.setState({
      i18n: i18n[inputLng],
      langBtn: inputLng,
    });
    this.props.changeLanguage(inputLng);
  };

  setMsg = (title, msg) => this.props.setFlashMessage(title, msg);

  onRemoveCredentials = () => {
    this.props.removeCredentials();
  };

  componentDidMount() {
    this.props.loadCredentials();
  }

  render() {
    return (
      <Router>
        <Fragment>
          {this.props.done !== true ? (
            <Login auth={this.props.path} />
          ) : (
            <LangContext.Provider value={this.state.i18n}>
              <AppLayout>
                <AppLayout
                  style={{
                    minHeight: `${window.innerHeight}px`,
                  }}
                >
                  <Header
                    langBtn={this.state.langBtn}
                    changeLang={this.changeLang}
                    checkSelectLang={this.state.checkSelectLang}
                  />
                  <ContentLayout>
                    {ListMenu.map((v, k) => {
                      return (
                        <PrivateRoute
                          key={k}
                          value={v}
                          done={this.props.done}
                          profile={this.props.profile}
                        />
                      );
                    })}
                  </ContentLayout>
                </AppLayout>
              </AppLayout>
            </LangContext.Provider>
          )}
        </Fragment>
      </Router>
    );
  }
}

const mapStateToProps = ({
  auth: { accessToken, done, profile, tokenExp },
  ui: { flash, langBtn },
}) => ({
  accessToken,
  done,
  profile,
  flash,
  tokenExp,
  langBtn,
});

const mapDispatchToProps = {
  removeCredentials: authActions.removeCredentials,
  loadCredentials: authActions.loadCredentials,
  clearFlashMessage: uiActions.clearFlashMessage,
  setFlashMessage: uiActions.setFlashMessage,
  changeLanguage: uiActions.changeLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
