import { createTypes, createAction } from "lib/action";

const LOAD_DATA = createTypes("loadData", "load");
const SHOW_DATA = createTypes("showData", "show");
const CREATE_DATA = createTypes("createData", "create");
const UPDATE_DATA = createTypes("updateData", "update");
const DELETE_DATA = createTypes("deleteData", "delete");
const DELETE_ITEM = createTypes("deleteItem", "deleteItem");
const EXPORT_DATA = createTypes("exportData", "export");
const IMPORT_DATA = createTypes("importData", "import");

const loadData = {
  request: (params, token) =>
    createAction(LOAD_DATA.REQUEST, { params, token }),
  success: (data) => createAction(LOAD_DATA.SUCCESS, { data }),
  failure: (err) => createAction(LOAD_DATA.FAILURE, { err }),
};

const showData = {
  request: (id, token) => createAction(SHOW_DATA.REQUEST, { id, token }),
  success: (data) => createAction(SHOW_DATA.SUCCESS, { data }),
  failure: (err) => createAction(SHOW_DATA.FAILURE, { err }),
};

const createData = {
  request: (params, token) =>
    createAction(CREATE_DATA.REQUEST, { params, token }),
  success: (data) => createAction(CREATE_DATA.SUCCESS, { data }),
  failure: (err) => createAction(CREATE_DATA.FAILURE, { err }),
};

const updateData = {
  request: (params, token) =>
    createAction(UPDATE_DATA.REQUEST, { params, token }),
  success: (data) => createAction(UPDATE_DATA.SUCCESS, { data }),
  failure: (err) => createAction(UPDATE_DATA.FAILURE, { err }),
};

const deleteData = {
  request: (id, token) => createAction(DELETE_DATA.REQUEST, { id, token }),
  success: (data) => createAction(DELETE_DATA.SUCCESS, { data }),
  failure: (err) => createAction(DELETE_DATA.FAILURE, { err }),
};

const deleteItem = {
  request: (params, token) =>
    createAction(DELETE_ITEM.REQUEST, { params, token }),
  success: (data) => createAction(DELETE_ITEM.SUCCESS, { data }),
  failure: (err) => createAction(DELETE_ITEM.FAILURE, { err }),
};

const exportData = {
  request: (params, token) =>
    createAction(EXPORT_DATA.REQUEST, { params, token }),
  success: (data) => createAction(EXPORT_DATA.SUCCESS, { data }),
  failure: (err) => createAction(EXPORT_DATA.FAILURE, { err }),
};

const importData = {
  request: (file, user_id, token) =>
    createAction(IMPORT_DATA.REQUEST, { file, user_id, token }),
  success: (data) => createAction(IMPORT_DATA.SUCCESS, { data }),
  failure: (err) => createAction(IMPORT_DATA.FAILURE, { err }),
};

export {
  loadData,
  showData,
  createData,
  updateData,
  deleteData,
  deleteItem,
  exportData,
  importData,
  LOAD_DATA,
  SHOW_DATA,
  CREATE_DATA,
  UPDATE_DATA,
  DELETE_DATA,
  DELETE_ITEM,
  EXPORT_DATA,
  IMPORT_DATA,
};
