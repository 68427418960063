import { fork } from "redux-saga/effects";
import watchMold from "./register/saga";
import watchTransferMold from "./transfer/saga";
import watchPhysicalcount from "./physicalcount/saga";

const watchFormWork = [
  fork(watchMold),
  fork(watchTransferMold),
  fork(watchPhysicalcount),
];

export default watchFormWork;
