import { call } from "redux-saga/effects";
import axios from "axios";

const baseUrl = `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}`;

const headers = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};

const headersUpload = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
        },
    };
};

const fetcher = {
    post: (url, params, token) => {
        return call(axios.post, `${baseUrl}/${url}`, params, headers(token));
    },
    get: (url, token) => {
        return call(axios.get, `${baseUrl}/${url}`, headers(token));
    },
    patch: (url, params, token) => {
        return call(axios.patch, `${baseUrl}/${url}`, params, headers(token));
    },
    delete: (url, token) => {
        return call(axios.delete, `${baseUrl}/${url}`, headers(token));
    },
    download: (params) => {
        return window.location.assign(`${baseUrl}/download/${params}`);
    },
    upload: (url, params, token) => {
        return call(axios.post, `${baseUrl}/${url}`, params, headersUpload(token));
    },
};

const masterData = {
    getOrderNumber: (params, token) =>
        axios.post(`${baseUrl}/getsaleorderbycode`, params, headers(token)),
    getAllDepartment: (params, token) =>
        axios.post(`${baseUrl}/getdepartmentall`, params, headers(token)),
    getConcreteMolds: (params, token) =>
        axios.post(`${baseUrl}/getconcretemolds`, params, headers(token)),
    getCompany: (token) => axios.get(`${baseUrl}/company/getddl`, headers(token)),
    getDayoff: (params, token) =>
        axios.post(`${baseUrl}/getdayoff`, params, headers(token)),
    getUsergroup: (token) =>
        axios.get(`${baseUrl}/usergroup/getddl`, headers(token)),
    getDepartment: (params, token) =>
        axios.post(`${baseUrl}/getdepartment`, params, headers(token)),
    getDivisions: (params, token) =>
        axios.post(`${baseUrl}/getdivision`, params, headers(token)),
    getLocations: (params, token) =>
        axios.post(`${baseUrl}/getlocation`, params, headers(token)),
    getPlants: (params, token) =>
        axios.post(`${baseUrl}/getplant`, params, headers(token)),
    getProduct: (params, token) =>
        axios.post(`${baseUrl}/getproduct`, params, headers(token)),
    getSites: (params, token) =>
        axios.post(`${baseUrl}/getsite`, params, headers(token)),
    getSoldTo: (params, token) =>
        axios.post(`${baseUrl}/getsoldto`, params, headers(token)),
    getStructure: (params, token) =>
        axios.post(`${baseUrl}/getstructure`, params, headers(token)),
    getSections: (params, token) =>
        axios.post(`${baseUrl}/getsection`, params, headers(token)),
    getMultiSections: (params, token) =>
        axios.post(`${baseUrl}/getmultisection`, params, headers(token)),
    getQcLevel: (params, token) =>
        axios.post(`${baseUrl}/getlevel`, params, headers(token)),
    getQC: (params, token) =>
        axios.post(`${baseUrl}/getqc`, params, headers(token)),
    getQCAvailable: (params, token) =>
        axios.post(`${baseUrl}/getqcavailable`, params, headers(token)),
    getQCDayoffStatus: (params, token) =>
        axios.post(`${baseUrl}/getqcstatusdayoff`, params, headers(token)),
    getUnloadtype: (params, token) =>
        axios.post(`${baseUrl}/getunloadtype`, params, headers(token)),
    geDamageReason: (params, token) =>
        axios.post(`${baseUrl}/getdamagereason`, params, headers(token)),
    getWorkSize: (params, token) =>
        axios.post(`${baseUrl}/getworksize`, params, headers(token)),
    getConcreteMoldSize: (params, token) =>
        axios.post(`${baseUrl}/getconcretemoldsize`, params, headers(token)),
    getMoldCode: (params, token) =>
        axios.post(`${baseUrl}/getmoldcode`, params, headers(token)),
    getTransporter: (params, token) =>
        axios.post(`${baseUrl}/gettransporter`, params, headers(token)),
    getUserSupervisor: (token) =>
        axios.get(`${baseUrl}/masterdatauser/getallsupervisoruser`, headers(token)),
    getQCAndSupervisor: (params, token) =>
        axios.post(`${baseUrl}/getqcandsupervisor`, params, headers(token)),
    getQcPosition: (token) =>
        axios.get(`${baseUrl}/getqcposition`, headers(token)),
};

const getErrorMsg = (error) => {
    const { response } = error;
    if (typeof response === "undefined") {
        return ""
    }

    if (typeof response.data.msg !== "undefined") {
        if (typeof response.data.msg === "object") {
            let msg = Object.keys(response.data.msg).map((x) => response.data.msg[x]);
            let errorMsg = "";
            if (typeof msg === "object") {
                msg.map((data) => {
                    if (typeof data === "object") {
                        errorMsg = Object.keys(data).map((x) => data[x]);
                    } else {
                        errorMsg += (errorMsg === "" ? "" : " ,") + data;
                    }
                    return true;
                });
            } else {
                errorMsg = msg;
            }
            return errorMsg;
        }
        return response.data.msg;
    } else if (typeof response.data.message !== "undefined") {
        if (typeof response.data.message === "object") {
            let msg = Object.keys(response.data.message).map((x) => response.data.message[x]);
            let errorMsg = "";
            if (typeof msg === "object") {
                msg.map((data) => {
                    if (typeof data === "object") {
                        errorMsg = Object.keys(data).map((x) => data[x]);
                    } else {
                        errorMsg += (errorMsg === "" ? "" : " ,") + data;
                    }
                    return true;
                });
            } else {
                errorMsg = msg;
            }
            return errorMsg;
        }
        return response.data.message;
    }

    return error.message + " : " + response.statusText;
};

const handleError = (error) => {
    const { response } = error;
    if (response.status === 401 || response.status === 403) {
        localStorage.clear();
        sessionStorage.clear();
        alert(getErrorMsg(error));
        window.location.href = "/signin";
    }
};

const logErrorMsg = (error) => {
    console.log("errorType", typeof error);
    console.log("error", Object.assign({}, error));
    console.log("getOwnPropertyNames", Object.getOwnPropertyNames(error));
    console.log("stackProperty", Object.getOwnPropertyDescriptor(error, "stack"));
    console.log(
        "messageProperty",
        Object.getOwnPropertyDescriptor(error, "message")
    );
    console.log("stackEnumerable", error.propertyIsEnumerable("stack"));
    console.log("messageEnumerable", error.propertyIsEnumerable("message"));
    console.log("message", error.message);
};

export {
    fetcher,
    masterData,
    logErrorMsg,
    getErrorMsg,
    handleError,
    headers,
    baseUrl,
};
