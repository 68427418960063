import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadMold(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post("mold", params, token);
    yield put(actions.loadMold.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* showMold(action) {
  try {
    const { id, token } = action.payload;
    const res = yield fetcher.get(`mold/${id}`, token);
    yield put(actions.showMold.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* updateMold(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post(`mold/update`, params, token);
    yield put(actions.updateMold.success(res.data));
    yield put(push("/qc-data"));
  } catch (error) {
    handleError(error);
    yield put(actions.updateMold.failure(getErrorMsg(error)));
  }
}

function* deletedMold(action) {
  try {
    const { id, token } = action.payload;
    yield fetcher.get(`mold/delete/${id}`, token);
    yield put(actions.deletedMold.success(id));
  } catch (error) {
    handleError(error);
    yield put(actions.deletedMold.failure(getErrorMsg(error)));
  }
}

function* importMold(action) {
  try {
    const { file, user_id, token } = action.payload;
    const formData = new FormData();
    formData.append("file", file.originFileObj);
    formData.append("user_id", user_id);
    const res = yield fetcher.upload(`mold/import`, formData, token);
    yield put(actions.importMold.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.importMold.failure(getErrorMsg(error)));
  }
}

function* downloadMold(action) {
  try {
    const { token } = action.payload;
    const res = yield fetcher.post(`mold/export`, { type: "template" }, token);
    yield put(actions.downloadMold.success(res.data));
    fetcher.download(res.data.data.replace(/\//g, "."));
  } catch (error) {
    handleError(error);
    yield put(actions.downloadMold.failure(getErrorMsg(error)));
  }
}

export default function* watchMold() {
  yield all([
    takeEvery(actions.LOAD_MOLD.REQUEST, loadMold),
    takeEvery(actions.SHOW_MOLD.REQUEST, showMold),
    takeEvery(actions.UPDATE_MOLD.REQUEST, updateMold),
    takeEvery(actions.DELETE_MOLD.REQUEST, deletedMold),
    takeEvery(actions.IMPORT_MOLD.REQUEST, importMold),
    takeEvery(actions.DOWNLOAD_MOLD.REQUEST, downloadMold),
  ]);
}
