import { fork } from "redux-saga/effects";
import watchQC from "./qc-data/saga";
import watchQcDayoff from "./vacation/saga";
import watchQcCalendar from "./calendar/saga";
import watchQcJob from "./qc-job/saga";
import watchQcSupervisor from "./supervisor/saga";
import watchQcRoutine from "./qc-routine/saga";

const watchQCMangement = [
  fork(watchQC),
  fork(watchQcDayoff),
  fork(watchQcCalendar),
  fork(watchQcJob),
  fork(watchQcSupervisor),
  fork(watchQcRoutine),
];

export default watchQCMangement;
