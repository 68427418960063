import {
  all,
  put,
  call,
  takeEvery,
} from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* checkLogin(action) {
  const {
    credentials: { username, password },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/auth/checkuser`,
      {
        username,
        password,
      }
    );
    let {
      data: {
        token,
        permissions,
        vehicle_list,
        location_list,
        vehicle_group_list,
        divisions,
        departments,
        sections,
        status,
      },
    } = res;

    if (status === "success") {
      let base64Url = token.split(".")[1];
      let base64 = base64Url
        .replace(/-/g, "+")
        .replace(/_/g, "/");
      let deToken = JSON.parse(window.atob(base64));

      yield call(() =>
        actions.storeCredentials(
          token,
          deToken,
          permissions,
          vehicle_list,
          location_list,
          vehicle_group_list,
          divisions,
          departments,
          sections
        )
      );
      yield put(
        actions.checkLogin.success(
          token,
          deToken,
          permissions,
          vehicle_list,
          location_list,
          vehicle_group_list,
          divisions,
          departments,
          sections
        )
      );

      //history.go(0);
    } else {
      yield put(
        actions.checkLogin.failure(
          "error",
          "data_not_complete",
          res.path_signup
        )
      );
    }
  } catch (err) {
    console.log("err:", err.message);
    if (err.message.indexOf("400") !== -1)
      yield put(
        actions.checkLogin.failure(
          "error",
          "Invalid username or password"
        )
      );
    else if (err.message.indexOf("503") !== -1)
      yield put(
        actions.checkLogin.failure(
          "error",
          "Service Unavailable!"
        )
      );
    else if (err.message.indexOf("502") !== -1)
      yield put(
        actions.checkLogin.failure("error", "Bad Gateway!")
      );
    else if (err.message.indexOf("504") !== -1)
      yield put(
        actions.checkLogin.failure(
          "error",
          "Gateway Timeout!"
        )
      );
    else
      yield put(
        actions.checkLogin.failure(
          "error",
          "An error occurred. Please try again later"
        )
      );
  }
}

export default function* watchAuthState() {
  yield all([
    takeEvery(actions.CHECK_LOGIN.REQUEST, checkLogin),
  ]);
}

export { checkLogin };
