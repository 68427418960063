import {
    LOAD_QC,
    SHOW_QC,
    CREATE_QC,
    UPDATE_QC,
    DELETE_QC,
    IMPORT_QC,
    EXPORT_QC,
} from "./actions";

const msg = {
    success_msg: "",
    err_msg: "",
};

const formdata = {
    id: "",
    code: "",
    firstname: "",
    lastname: "",
    division_id: "",
    department_id: "",
    section_id: "",
    plant_id: "",
    remark: "",
    level_id: "",
    username: "",
    password: "",
};

const initialState = {
    lists: [],
    achievement_list: [],
    total_rows: 0,
    loading: false,
    shouldReload: false,
    data: formdata,
    ...msg,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_QC.REQUEST:
        case SHOW_QC.REQUEST:
        case CREATE_QC.REQUEST:
        case DELETE_QC.REQUEST:
        case IMPORT_QC.REQUEST:
        case EXPORT_QC.REQUEST:
            return {
                ...state,
                data: formdata,
                ...msg,
                loading: true,
                shouldReload: false,
            };

        case UPDATE_QC.REQUEST:
            return {
                ...state,
                ...msg,
                loading: true,
                shouldReload: false,
            };

        case LOAD_QC.SUCCESS:
            return {
                ...state,
                lists: action.payload.data.data,
                achievement_list: action.payload.data.achievement_list,
                total_rows: action.payload.data.total,
                loading: false,
            };

        case SHOW_QC.SUCCESS:
            return {
                ...state,
                data: action.payload.data.data,
                loading: false,
            };

        case CREATE_QC.SUCCESS:
            return {
                ...state,
                success_msg: "save_success",
                loading: false,
            };

        case UPDATE_QC.SUCCESS:
            return {
                ...state,
                success_msg: "edit_success",
                loading: false,
                shouldReload: true,
            };

        case DELETE_QC.SUCCESS:
            return {
                ...state,
                success_msg: "delete_success",
                loading: false,
                shouldReload: true,
            };
        case IMPORT_QC.SUCCESS:
            return {
                ...state,
                success_msg: "import_success",
                loading: false,
                shouldReload: true,
            };

        case EXPORT_QC.SUCCESS:
            return {
                ...state,
                success_msg: "export_success",
                loading: false,
            };

        case LOAD_QC.FAILURE:
        case SHOW_QC.FAILURE:
        case CREATE_QC.FAILURE:
        case UPDATE_QC.FAILURE:
        case DELETE_QC.FAILURE:
        case IMPORT_QC.FAILURE:
        case EXPORT_QC.FAILURE:
            return {
                ...state,
                err_msg: action.payload.err,
                loading: false,
                shouldReload: false,
            };

        default:
            return state;
    }
};
