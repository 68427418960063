const en = {
    titleaction: {
        create: "Create",
        edit: "Edit",
        detail: "Detail",
    },
    sidebar: {
        management: "Management",
        user: "User",
        usergroup: "User Group",
        permission: "Permission",
        qc: "QC Management",
        calendar: "QC Schedule",
        assign_job: "Assign Job",
        vacation: "Vacation Management",
        qc_data: "QC Data",
        qc_job: "Job",
        qc_raking: "QC Ranking",
        tracking: "Tracking",
        concrete_form: "Mold",
        register_formwork: "Register",
        qc_tracking: "QC Tracking",
        form_tracking: "Mold Tracking",
        transfer_formwork: "Transfer Mold",
        physicalcount_formwork: "Physicalcount Mold",
        supervisor: "Supervisor",
        mold_status_report: "Mold Status Report",
        milladd_report: "Mill Add Report",
        concrete_report: "Concrete Report",
        supervisor_working_report: "Supervisor Working Report",
        qc_working_report: "QC Working Report",
        concrete_report2: "Concrete",
        supervisor_working_report2: "Supervisor Working",
        qc_working_report2: "QC Working",
        mold_status_report2: "Mold Status",
        qc_routine: "QC Routine",
        milladd_report2: "บันทึกเวลารถโม่",
        qc_ranking: "QC Ranking"
    },
    header: {
        notification: "Notification",
        tokenExpired: "Token Expired",
        changeLanguage: "Change Language",
        titleAccount: "Account",
        home: "Home",
        logout: "Logout",
        login: "Login",
    },
    action: {
        add: "Add",
        save: "Save",
        edit: "Edit",
        back: "Back",
        delete: "Delete",
        create: "Create",
        close: "Close",
        ok: "Ok",
        cancel: "Cancel",
        detail: "Detail",
        import: "Import",
        export: "Export",
        download: "Download",
        search: "Search",
        yes: "Yes",
        no: "No",
    },
    user: {
        systeminfo: "System Information",
        userinfo: "Employee Information",
        spacialinfo: "Other Information",
    },
    assign_filter: {
        statusOrder: "สถานะ",
        date: "วันที่",
        since: "ตั้งแต่",
        to: "ถึง",
        division: "กิจการ",
        department: "แผนก",
        assign_qc: "Choose QC",
    },

    assign_table: {
        reserv: "จอง",
        reserv_place: "ที่งานจอง",
        work_reserv: "งานจอง",
        code: "รหัส",
        unit_name: "ชื่อหน่วยงาน",
        size: "ขนาดงาน",
        total: "จองรวม",
        name: "ชื่อ",
        te: "เท",
        plant: "โรงงาน",
        site: "หน่วยงาน",
        mortar: "แบบก้อนปูน",
        test_cpac: "CPACทดสอบเอง",
        customer_test: "ลูกค้าทดสอบเอง",
        cpac_send: "CPACนำส่งให้",
        over: "ไม่เกิน",
        customer: "ชื่อลูกค้า",
        assign_job: "Assign Job",
        dashboard_mold: "Concrete Mold Summary Dashboard",
    },

    qc: {
        code: "Code",
        title: "QC Information",
        name: "Name",
        surname: "Surname",
        level: "Level",
        division: "Division",
        position: "Position (CPAC) ",
        company: "Company",
        section: "Section",
        department: "Department",
        remark: "Remark",
        last_update: "Last Updated",
        plant: "Plant",
        supervisor: "Supervisor",
        qc_code: "QC Code",
        qc: "QC",
        plant_code: "Plant Code",
    },

    message: {
        save_success: "Save Success",
        edit_success: "Edit Success",
        delete_success: "Delete Success",
        save_fail: "Save Failed",
        edit_fail: "Edit Failed",
        delete_fail: "Delete Failed",
        error: "An error occured",
    },

    dayoff: {
        business_leave: "Business leave",
        maternity_leave: "Maternity leave",
        sick_leave: "Sick leave",
        sterlie_leave: "Sterlie leave",
        vacation_leave: "Vacation leave",
        general: "General leave",
        qc_dayoff: "QC Dayoff",
        holiday: 'Holiday',
    },

    qcunit: {
        division: "Division",
        department: "Deparment",
        section: "Section",
        site: "Site",
        plant: "Plant",
    },
    supervisor: {
        supervisor: "supervisor name",
        supervisor_code: "supervisor code",
        count_checkin: "number of checkin",
        count_transfer_data: "จำนวนการนับแบบหล่อ",
        count_physical_data: "จำนวนก้อนปูนที่ทำ",
        count_supervisor_checkin_checkout: "เช็คอินงานบริการเทคนิค (ครั้ง)",
        start_date_at: "วันที่",
        start_time_at: "เวลาที่เริ่มงาน",
        end_date_at: "วันที่จบงาน",
        end_time_at: "เวลาที่จบงาน",
        task_type: "ประเภทงาน",
        site_code: "รหัสหน่วยงาน",
        address: "ชื่อหน่วยงาน",
        latlng: "Lat, Lng",
        purpose: "จุดประสงค์การเช็คอิน",
        other_purpose: "จุดประสงค์อื่นๆ",
    },

    testtype: {
        customer: "Customer test",
        cpac: "CPAC test",
        cpac_vendor: "CPAC send",
    },

    worksheetstatus: {
        opened: "opened",
        scheduled: "scheduled",
        working: "working",
        closed: "closed",
        cancel: "cancelก",
    },

    mold: {
        cylinder_15_30: "Cylinder15x30",
        cylinder_10_20: "Cylinder10x20",
        cube_15_15_15: "Cube15x15x15",
        cube_10_10_10: "Cube10x10x10",
        beam: "beam",
    },

    resource: {
        web: "Web",
        import: "Import",
        cpac: "CPAC",
    },

    a: {
        arrived_at: "Arrived At",
        allStatusDashCameras: "all",
        amountPlantIsRequired: "Amount Plant is Required",
        alarm: "Alarm",
        alert: "Alert",
        affiliation: "Affiliation",
        allowParkingInside: "Allow Parking Inside Only",
        allowParkingOutside: "Allow Parking Outside Only",
        allowPTODoorInside: "Allow Open Inside Only",
        allowPTODoorOutside: "Allow Open Outside Only",
        after5min: "After 5 Min.",
        after30min: "After 30 Min.",
        after1hour: "After 1 Hour",
        after4hour: "After 4 Hour",
        after8hour: "After 8 Hour",
        after1day: "After 1 Day",
        alertNoti: "Alert Only",
        actionRequired: "Action Required",
        allowWorkingTime: "Allow Working Time",
        apply: "Apply",
        all: "All",
        add: "Add",
        amount: "Amount",
        addVehicleGroup: "Add Vehicle Group",
        action: "Action",
        arab: "Arab",
        african: "African",
        adjustTheTable: "Adjust The Table",
        actualTransitDistance: "Actual Transit Distance",
        account: "Account",
        address: "Address",
        active: "Active",
        agency: "Agency",
        administrator: "Administrators",
        addInformation: "Add Information",
        alarmAndAlertReportClassifiedByDailyCarRegistration:
            "Alarm And Alert Report Classified By Daily Car Registration",
        alarmAndAlertReportClassifiedByYearlyCarRegistration:
            "Alarm And Alert Report Classified By Yearly Car Registration",
        avgEarningJob: "Avg. Earning/Job",
        ACCUMULATEEARNINGSTATISTICS: "ACCUMULATE EARNING STATISTICS",
        assignTo: "Assign To",
        addARow: "Add A Row",
        alertAlarm: "Alert Alarm",
        announcement: "Announcement",
        announcementBroadcast: "Announcement Broadcast",
        allowSharedPartnerEdit: "Partner can Edit",
        addDrivinglicense: "Add Driving License",
        amountPlant: "Amount Plant",
        accessory: "Accessory",
        and: "AND",
        appointment_date: "Appointment date",
        appointment_place: "Appointment place",
        amphur: "District",
        aliasTableCompany: "Alias",
        actionTime: "Action Time",
        accountName: "Account Name",
        appointmentLocation: "Appointment Location",
        activeStatusImage: "Active Status Image",
        attachment_type: "Attachment type",
        alert_setting_filter: "Please set filter setting report",
        available: "Available",
        already_have_account: "Already have an account?",
        add_contact: "Add Contact",
        admin_system: "Admin System",
        add_destination: "Add Destination",
        add_location: "Add Location",
        addGroup: "Add To Group",
        actionby: "Action By",
        additional_remark: "Additional Remark",
        additional_report_at: "Create At",
    },

    b: {
        BOOM: "BOOM",
        behavior: "Behavior",
        BOOKINGSTATISTICS: "BOOKING STATISTICS",
        booking: "Booking",
        baseTemplate: "Base Template",
        base: "Base",
        bookedDate: "Booked Date",
        busStopPoint: "Bus Stop Point",
        busStop1: "Bus Stop 1",
        busStop2: "Bus Stop 2",
        boxNumber: "Device No.",
        boxID: "Device ID",
        bookingDate: "Booking Date",
        button: "Search",
        back: "Back",
        bookingRemark: "Booking Remark",
        bookingPool: "Booking Pool",
        bookingOverview: "Booking Overview",
        brand: "Brand",
        behavior_report_menu: "Behavior",
        bodyNoOfInstall: "Body no of install",
        bodyNo: "Body No.",
        body_email: "Body",
        boatDeliveryDashboard: "Boat Delivery Status",
        boatDeliveryReport: "Boat Delivery Report",
        broken: "broken",
        booking_qty: "Booking Quantity",
    },

    c: {
        confirm_quotation_success: "Confirm Quotaion Success",
        confirm_quotation_unsuccess: "Confirm Quotaion Fail",
        confirm_quotation: "Confirm Quotaion",
        confirm_quotation_desc: "Do you want to confirm this quotation",
        carNotOnline: "Car not online",
        connectionLost: "Connection lost",
        contactSupportForUserThisMenu: "Please Contact Support To Use This Feature",
        criteria: "Criteria",
        continuousDrive: "Continuously Drive",
        continuousDriveInADay: "Continuously Drive In a Day",
        criticalLevel: "Critical Level",
        category: "Category",
        concreteDeliveryDashboard: "Concrete Delivery Status",
        collapse: "Collapse",
        cancel: "Cancel",
        close: "Close",
        clear: "Clear",
        button: "Search",
        customer: "Customer",
        customerDetail: "Passenger Detail",
        chineseKoreanJapanese: "Chinese/Korean/Japanese",
        cancelReason: "Reason Of Cancellation",
        clearUp: "Clear Up",
        configuration: "Configuration",
        connection: "Connection",
        currentAddress: "Current Address",
        chauffeur: "Driver",
        communityArea: "Community Area",
        crossroad: "Junction",
        createdAt: "Created At",
        customerType: "Customer Type",
        criticality: "Criticality",
        comment: "Description",
        channel: "Channel",
        closed: "Closed",
        changeLanguage: "Change Language",
        confirmToDeleteThisItem: "Confirm To Delete This Item.",
        carService: "Car Service",
        company: "Company",
        confirmPassword: "Confirm Password",
        cancelled: "Cancelled",
        confirm: "Confirm",
        changeProduct: "Change Product",
        customerName: "Customer Name",
        confirmToAddThisItem: "Confirm To Add This Item.",
        confirmToEditThisItem: "Confirm To Edit This Item.",
        custom: "Custom",
        contractor: "Contractor",
        currentVehicle: "Current Vehicle",
        courseName: "Course Name",
        Color: "Color",
        companyBilling: "Company Billing",
        chasisSerialNumber: "Chasis Serial Number",
        createdBy: "Created By",
        CompareVehicleSccoReport: "Compare Vehicle SCCO Report",
        cycleOfDrumRotation: "Cycle of drum rotation",
        contact_name: "Contact name",
        contact_telno: "Contact telno",
        confirm_appointment_place: "Confirm appointment place",
        created_by: "Created by",
        confirm_type: "Confirm type",
        compulsory: "Compulsory",
        carTax: "Car tax",
        customerID: "Customer ID",
        companyCode: "Company Code",
        closeFixed: "Close:Fixed",
        closeNotAvailable: "Close: Not available",
        contactName: "Contact Name",
        contactTelno: "Contact Tel No",
        contact: "Contact",
        Contactmanagement: "Contact Management",
        camera_position: "Camera postion",
        camera_back: "Back",
        cc_email: "Cc",
        create_trip: "Create trip",
        company_require: "Company is Required",
        confirm_password: "Confirm password",
        captcha_require: "Please complete the captcha",
        cell_phone: "Mobile phone",
        contactinfo: "Contact Info",
        copy_id_card: "Copy of ID Card",
        copy_driver_license: "Copy of Driver's License",
        company_select_require: "Company is require",
        contactPerson: "Contact Person",
        companyProfile: "Company Profile",
        company_verify: "Company Verify",
        confirmVerifyCompany: "Do you want to verify this company?",
        confirmVerifyVehicle: "Do you want to verify this vehicle?",
        clickToViewFile: "Click to View File",
        create_success: "Create Success",
        create_fail: "Create Fail",
        create_user: "Create User",
        create_usergroup: "Create Usergroup",
        concrete_mold_types: "Concrete Mold Types",
        concrete_mold_code: "Concrete Mold Code",
        checkin: "Check in",
        checkout: "Check Out",
        concrete_code: "Concrete Code",
        count: "Total",
        concrete_test_age: "Concrete Test Age",
        concrete_collector_code: "Concrete Collector Code",
        concrete_collector: "Concrete Collector",
        cast_date: "Cast Date",
        collect_date: "Collect Date",
        collect_concrete: "Collect Concrete",
    },

    d: {
        department: "Department",
        dayTime: "Day Time",
        driving: "Driving",
        daily: "Daily",
        diff: "Diff",
        deliveryStatus: "Delivery Status",
        dailyCarStatusReport: "Daily Car Status Report",
        dailyDrivingBehaviorReport: "Daily Driving Behavior Report",
        driveOverTheSpeedLimitInTheCrossroads:
            "Drive Over The Speed Limit In The Crossroads",
        day: "Day",
        driveTooFastInTheNormalRoute: "Drive Too Fast In The Normal Route",
        driveTooFastInTheCommunity: "Drive Too Fast In The Community",
        dateRange: "Date Range",
        DailyAlarmAndAlertReportByDriver: "Daily Alarm And Alert Report By Driver",
        deletedAt: "Deleted At",
        deletedBy: "Deleted By",
        deleteReason: "Delete Reason",
        deleteInformation: "Delete Information",
        draft: "Draft",
        detail: "Detail",
        doYouWantToConfirmSend: "Do You Want To Confirm Send?",
        delete: "Delete",
        distance: "Distance",
        deliverPoint: "Deliver Point",
        doYouWantToSendAnEmailToUsersToVerifyTheirIdentity:
            "Do you want to send an email to users to verify their identity?",
        doYouWantToDeleteTheseItems: "Do You Want To Delete These Items?",
        doYouWantToCancel: "Do You Want To Cancel?",
        doYouWantToAddTheseItems: "Do You Want To Add These Items?",
        descrip: "Description",
        dateTime: "Date/Time",
        description: "Description",
        description_onetime: "Onetime Description",
        description_routine: "Routine Description",
        dateOfSubmission: "Request Date",
        dateSubmit: "Delivery Date",
        displayTime: "Display Time",
        deliveryAddress: "Delivery Address",
        driveContinuouslyForMoreThan4Hours: "Driving Time > 4 hrs",
        driveContinuouslyForMoreThan10HoursDay: "Driving Time > 10 hrs/day",
        doNotParkBesideTheRoad: "No Parking On Sideways",
        drivingPeriodRange: "Driving Period Range (min)",
        doYouWantToEditTheseItems: "Do you want to edit these items?",
        doNotSwipeCardsOrDamageCards: "Please Check Your Card",
        doYouWanttoReActive: "Do You Want To Reactivate?",
        driverName: "Driver Name",
        driverCode: "Driver Code",
        driverPhoneNumber: "Phone Number",
        destinationDetail: "Destination Detail",
        destination: "Destination",
        done: "Done",
        drivingTime: "Driving Time",
        destinationLocation: "Destination",
        data: "Data",
        draw: "Draw",
        daYouWantToReActive: "Do You Want To Re-Active?",
        dashCamera: "Dash Camera",
        deliveryItemPool: "Delivery Item Pool (EZY GPS)",
        deliveryItemDeleteHistoryPool:
            "Delivery Item Delete History Pool (EZY GPS)",
        deliveryItem: "Delivery Item",
        dataVisibility: "Data Visibility",
        disconnected: "Disconnected",
        dateOfBirth: "Date of Birth",
        drivinglicense: "Driving License",
        drivingIicenseNumber: "Driving License Number",
        drivingIiceneType: "Driving Licene Type",
        driverHistory: "Diver History",
        date: "Date",
        detailOfConcreteTruck: "Detail Of Concrete Truck",
        drumCapacity: "Drum Capacity",
        dischargeDenominator: "Discharge Denominator",
        dataForDLT: "Data For DLT",
        driver2: "Driver 2",
        driver1: "Driver 1",
        DrivingdistanceReport: "Driving Distance Report",
        DistfromlastmaintenanceReport: "Distfromlastmaintenance Report",
        DriverinformationReport: "Driverinformation Report",
        distance_more_than: "Total distance is considered work/day (km)",
        DrivinglicensescanlogReport: "Driving license scan log Report",
        DrivinglogReport: "Driving Log Report",
        DrivinglogspecialReport: "Driving Log Report (New)",
        DozeoffriskReport: "Doze off risk Report",
        DeliveryReport: "Trip delivery Report",
        DailyReport: "Trip daily Report",
        distance_less_than: "Distance less than",
        DrivinghourReport: "Driving hour Report",
        deliver: "Deliver",
        DrivingoverlimitReport: "Driving over limit Report",
        dangerous: "Dangerous",
        detailproblem: "Detail Problem",
        doc_no: "Document No.",
        document: "Document",
        durationOfPTOGate1On: "Duration of PTO/Gate1 On",
        duration: "Duration",
        mdvrdownloadfile: "Download Video MDVR",
        download: "Download",
        detail_problem: "Detail problem (other)",
        deliverytrip: "Delivery Trip",
        Delivered: "Delivered",
        deliver_status: "Deliver status",
        destinationLocationcode: "Destination code",
        device: "Device",
        deliver_duration: "Start at - End at",
        dataType: "Type",
        daysBeforeNotification: "Remind Day",
        defaultLang: "Default lang",
        defaultLatitude: "Default latitude",
        defaultLongitude: "Default longitude",
        detailProblemServiceMoveDevice: "Detail Problem",
        date_time: "Date Time",
        downloadcertificate: "Download Certificate",
        default: "Default",
        devicePhone: "Device Phone",
        defaultImageBylocationType: "Default Image By Location Type",
        DailyiconsiamReport: "Shuttle Boat – Shuttle Bus Service Daily Report",
        dashboard: "Dashboard",
        duration_time: "Duration time",
        date_entry: "Entry date",
        date_out: "Out date",
        date_time_entry: "Date-time entry",
        date_time_out: "Date-time out",
        donothaveaccount: "Don't have an account?",
        direct: "Direct",
        driver_required: "Please select driver",
        device_required: "Please select vehicle",
        dateTranferConcreteMold: "Date Tranfer Concrete Mold",
        dayWork: "Working Day",
        dayOff: "Day Off",
        dayLeave: "Leave Day",
        dp: "DP",
    },

    e: {
        end_unload_at: "Unload End",
        eventEnd: "Event End",
        expand: "Expand",
        edit: "Edit",
        estFare: "Est. Fare",
        estTransitDuration: "Travel Duration",
        estTransitDistance: "Travel Distance",
        europeAmerican: "Europe/American",
        engineStatus: "Engine Status",
        engineOn: "Engine On",
        engineOnWithImg: "Engine On with Image",
        engineOff: "Engine Off",
        engineOnDesc: "GPS sending position while engine on.",
        engineOnWithImgDesc:
            "GPS sending position while engine on with Dash camera snapshot images.",
        engineOffDesc: "GPS sending position while engine off.",
        endLocationCode: "End Location Code",
        employeeID: "Employee ID",
        event: "Event",
        EarningReceivedSTATISTICS: "Earning/Received STATISTICS",
        earningType: "Earning Type",
        eventAlarm: "Event Alarm",
        etc: "Etc.",
        emergencyCase: "Emergency Case",
        emergencyStatus: "Emergency Status",
        engine: "Engine",
        endLocationName: "End Location Name",
        endDestinationCode: "End Destination Code",
        endDestinationName: "End Destination Name",
        email: "Email",
        endDate: "End Date",
        earningOverview: "Earning Overview",
        eventMap: "Event Map",
        expireDate: "Expire Date",
        endValidDate: "End Valid Date",
        emailVerificationStatus: "Email Verification Status",
        emailVerification: "Pending",
        expense: "Expense",
        excel: "Excel",
        EventReport: "Event Report",
        engine_on_time_end_at: "Engine on time end at",
        engine_on_time_start_at: "Engine on time start at",
        engine_off_time_end_at: "Engine off time end at",
        engine_off_time_start_at: "Engine off time start at",
        efficiency_report_menu: "Efficiency",
        energy_report_menu: "Energy",
        export_date_at: "Issue date",
        engineOnDuration: "Engine-on duration",
        endAt: "End at",
        expenseBaht: "Expense (Baht)",
        expiryDate: "Expiry Date",
        employeeInformation: "Employee Information",
        emailAE: "Email AE",
        editStatus: "Edit Status",
        emailVerificationSuccessful: "Verification Successful",
        email_subject: "Email subject",
        emailorphone: "Email or Phone",
        emailorphone_require: "Email or Phone is require",
        emailorphone_invalid: "Email or Phone is invalid",
        email_require: "Email is required",
        email_invalid: "Email is invalid",
        expenses: "Route - Expense",
        evaluationsSuccessfully: "Evaluations Successfully",
        edit_user: "Edit User",
        edit_usergroup: "Edit Usergroup",
        export_template: "Download Template",
    },

    f: {
        formwork_select: "Choose Mold",
        fillPosition: "Fill Position",
        finished: "Finished",
        file: "File",
        fine: "Fine",
        fuel: "Fuel",
        firstName: "First Name",
        facilityName: "Location Name",
        facilityName2: "Location Name2",
        facilityName3: "Location Name3",
        findDistance: "Find Distance",
        findSpace: "Find Space",
        fare: "Fare (฿)",
        findCustomer: "Search passenger",
        fillInFrom: "Fill In From",
        fillInfomation: "Fill Infomation",
        finish: "Finish",
        filter: "Filter",
        firmwareVersion: "Firmware Version",
        fuelType: "Fuel Type",
        flateNumber: "Flate Number",
        fuelTankSize: "Fuel Tank Size",
        fuelA: "Fuel A",
        fuelB: "Fuel B",
        fuelC: "Fuel C",
        fuelUsage: "Fuel Usage",
        fuelRate: "Fuel Rate",
        fullTank: "Full Tank",
        forgot_password: "Forgot Password",
        filter_type1: "First move out plant to last move in plant",
        filter_type2: "First engine on to last engine off",
        filter_type: "Filter type",
        first_dest: "Start Position",
        firstMaintenanceConditions: "First maintenance conditions",
        file_location: "File location",
        file_size: "File size",
        finishRefuel: "Finish Refuel",
        fixed: "Fixed",
        Fullscreen: " Fullscreen",
        fileapetition: "File a petition",
        front: "Front",
        flatRate: "Flat-Rate",
        fillInForm: " Fill in Form",
        filenotreadydownload: "Cannot download at this time",
        fail: "Fail",
        fuelremainrateReport: "Fuel Remain Rate Report",
        filter_by: "Filter by",
        forgotpassword: "Forgot your password?",
        fail_save: "Can not save,Please save again",
    },

    g: {
        gpsNotFixed: "GPS Not Fixed",
        gpsNotFixedDesc: "Position Inaccurate.",
        general: "General",
        getVehicles: "Get Vehicles!",
        groupByLocation: "Group By Location",
        groupVehicleType: "Vehicle Group/Type",
        group: "Group",
        gate1: "Door 1",
        gate2: "Door 2",
        gate3: "Door 3",
        generateMap: "Generate Map",
        gasStation: "Gas Station",
        gpsCertification: "GPS Certification",
        gpsCertRequest: "GPS Cert. Request (EZY GPS)",
        gpsCertRequestHistory: "GPS Cert. Request History (EZY GPS)",
        generalLocation: "General Location",
        greater_than: "Greater than",
        GpsstatusReport: "GPS Status Report",
        group_by: "Group by",
        group_idle: "Only Idle",
        group_speed: "Only Overspeed",
        general_report_menu: "General",
        greaterOrEqual: "Greater or equal",
        gps_update: "GPS Update",
        googleMapApiKey: "Google Map Api Key",
        group_data: "Group Data",
        gallery_name: "Name",
        gallery: "Gallery",
        goBackToUseImagesByLocationType: "Go Back To Use Images By Location Type",
        get_verify_code: "Request OTP",
        group_name_require: "Group Name is require",
    },

    h: {
        hour: "Hours",
        hotel: "Hotel",
        high: "High",
        home: "Home",
        hardwareID: "Hardware ID",
        header: "Header",
        horsePower: "Horse Power",
        height: "Height",
        hours: "Hours",
        help_menu: "Helps",
        history_servicerepairdevice: "Historys",
        honeytoastlivestream: "Honey Toast Live Stream",
        honeytoastdownloadfile: "Download video Honey toast",
        honeytoastplayback: "Playback video Honey toast",
        have_porter: "Yes",
        have_no_porter: "No",
    },

    i: {
        invalid_username_password: "Incorrect username or password",
        indexFilterOption_All: "All",
        indexFilterOption_Unassign: "Unassign",
        indexFilterOption_Waiting: "Waiting Confirm",
        indexFilterOption_Confirm: "Confirm",
        indexFilterOption_Cancel: "Cancel",
        indexFilterOption_Finish: "Finish",
        imagesNotUpdate: "Images not update",
        inside: "Inside",
        immediatelyAcess: "Immediately",
        itemSelected: "Select",
        itemLeft: "Not Select",
        ignoredAlert: "Ignored",
        iconDesc: "Icon Desc.",
        indian: "Indian",
        infomation: "Information",
        imageMustSmallerThan5MB: "Image Must Smaller Than 5MB!",
        internalOperation: "Internal Operation",
        issuedDate: "Issued Date",
        id: "ID",
        idleFuelRate: "Idle Fuel Rate",
        imageFromDashCamera: "Dash Camera",
        InsuranceReport: "Insurance Report",
        insurance_report_menu: "Insurance",
        invoice_number: "Invoice number",
        invoice_date: "Invoice Date",
        insure_report_menu: "Insure",
        insuranceID: "Insrance ID",
        insurance: "Insurance",
        install: "Install",
        installStatus: "Install Status",
        installDevice: "Install Device",
        imageFromTheGallery: "Image From The Gallery",
        include_engine_off: "Include engine off data",
        input_verify_code: "Input verify code",
        info_verify_code: "Request OTP with Phone and Link verify Email",
        identification_documents: "Identification Documents",
        import: "Import",
        image_physicalcount: "Physical Count Images",
        image_transfer: "Transfer Concrete Images",
        image_concrete: "Concrete Images",
        image_moldstatus: "Mold Status Images",
        image_sendlab: "Mold Send Lab Images",
        image_qcworking: "QC Working Images",
        image_supervisorworking: "Supervisor Working Images",
        image_additional: "Additional Images",
    },

    j: {
        jobDetail: "Job Detail",
        jobStatus: "Job Status",
        jobType: "Job Type",
        jobTypeSTATISTICS: "Job Type STATISTICS",
        jobId: "Job ID",
        joinDate: "Join Date",
        jobEnd: "End Date",
        jobStartAt: "Job Start At",
    },

    k: {
        kpiEvent: "Event",
        kpiDateTime: "Date/Time",
        kind: "Kind",
        kmH: "km/h",
        keywordAtleast3character: "Keyword At Least 2 Character",
        km: "km.",
    },

    l: {
        list: "List",
        limitOpenDuration: "Limit Duration",
        limitParkingDuration: "Limit Duration",
        limitParkingInsideLocation: "Limit Parking Inside Location",
        limitParkingOutsideLocation: "Limit Parking Outside Location",
        limitSuddenlyBreakThreshold: "Suddenly Break Threshold",
        limitAggesiveAccelerationThreshold: "Aggesive Acceleration Threshold",
        limitDrivingAggesiveThreshold: "Driving Aggesive Threshold",
        limitSnoozeThreshold: "Snooze Threshold",
        limitUsingPhoneThreshold: "Using Phone Threshold",
        limitParking: "Limit Parking",
        limitGoInsideArea: "Limit Inside Area Duration",
        limitGoOutsideArea: "Limit Outside Area Duration",
        load: "Load",
        loadStartAt: "Load Start At",
        loadAmount: "Load Amount",
        lastName: "Last Name",
        location: "Location",
        locationGroup: "Location Group",
        lookingForSomething: "Looking For Something?",
        lookingForPin: "Please enter location",
        lostConnectio: "Disconnected",
        lastRequestOn: "Latest Request At",
        low: "Low",
        logout: "Logout",
        login: "Login",
        locationCode: "Location Code",
        latitudeIsRequired: "Latitude is Required",
        longitudeIsRequired: "Longitude is Required",
        latitude: "Latitude",
        longitude: "Longitude",
        locationInformation: "Location Information",
        loadpoint: "Load Point",
        locationTemporary: "Location Temporary",
        link: "Link",
        latestDriver: "Latest Driver",
        lazada: "Lazada",
        length: "Length",
        limitDrivingHourTime: "Limit driving hour time (minutes)",
        locationGroupName: "Location Group Name",
        less_than: "Less than",
        LoginhistoryReport: "Login History Report",
        login_success: "Login success",
        login_fail: "Login Fail",
        LocationInSystem: "Location in system",
        Location: "Location",
        locationShareTo: "Share To",
        locationShareFrom: "Share From",
        lessOrEqual: "Less or equal",
        lastest_signal: "Lastest Pos",
        lastmaintenanceAt: "Last Maintenance At",
        LocationOfVehicle: "Affiliation Location of Vehicle",
        LocationOfDeviceUser: "Affiliation Location of Driver",
        longdoMapApiKey: "Longdo Map Api Key",
        liveStream: "Live Stream",
        locationAndRoute: "Location And Route",
        locationGroupNameIsRequired: " Location Group Name is Required",
        locationGroupTypeIsRequired: " Location Group Type is Required",
        locationName1IsRequired: " Location Name1 is required and can't be empty",
        locationCodeIsRequired: " Location Code is required and can't be empty",
        latitudeIsRequiredAndCantBeEmpty:
            " Latitude is required and can't be empty",
        LongitudeIsRequiredAndCantBeEmpty:
            " Longitude is required and can't be empty",
        logisticGroup: "Logistic Group",
        logisticGroupName: "Logistic Group Name",
        logisticLogo: "Logo",
        logistic_group_add:
            "Data not found. Do you want to create logistic group ?",
        latLng: "Lat, Lng",
        lab_date: "Lab date",
    },

    m: {
        meterDashCameras: "meter",
        monthly: "Monthly",
        moisture: "Humidity",
        month: "Month",
        miss: "Miss",
        mrs: "Mrs.",
        mr: "Mr.",
        m: "m.",
        medium: "Medium",
        message: "Message",
        masterData: "Master Data",
        min: "Min",
        mixingDrum: "Drum",
        map: "Map",
        mapView: "Map View",
        meterStatus: "Meter Status",
        monitoring: "Monitoring",
        match: "Match",
        maxDrumCapacity: "Max Drum Capacity",
        mileNo: "Mile No.",
        model: "Model",
        machineSerialNumber: "Machine Serial Number",
        meterSN: "Meter S/N",
        MaintenanceReport: "Maintenance Report",
        more_1_min: "> 1 min",
        more_5_mins: "> 5 mins",
        more_10_mins: "> 10 mins",
        more_5_sec: "> 5 secs",
        more_10_sec: "> 10 secs",
        more_30_sec: "> 30 secs",
        more_60_sec: "> 60 secs",
        Minimal: "Minimal",
        managementdevice_report_menu: "Management Devices",
        MaintenancedeviceconcludeReport: "Maintenance Device Report",
        maintenance_status: "Status",
        maintenanceConditions: "Maintenance Conditions",
        maintenance: "Maintenance",
        minute: "Minute",
        maintenance_type: "Maintenance type",
        move_in: "Move in",
        move_out: "Move out",
        maintenancedevice_report_menu: "Maintenance devices",
        manage_product: "Manage products",
        mapType: "Map Type",
        moveDevice: "Move Device",
        move: "Move",
        management_menu: "Management",
        meter: "Meter",
        m_january: "January",
        m_february: "February",
        m_march: "March",
        m_april: "April",
        m_may: "May",
        m_june: "June",
        m_july: "July",
        m_august: "August",
        m_september: "September",
        m_october: "October",
        m_november: "November",
        m_december: "December",
        max_speed: "Max speed",
        market: "Market",
        moldCount: "Count Mold",
        mold_type: "Mold Type",
        mold_status: "Mold Status",
        milladd_report: "Mill Add",
    },

    n: {
        none: "None",
        now: "Now",
        noEntry: "No Entry",
        noExit: "No Exit",
        nameAndType: "Name & Type",
        notification: "Notification",
        notificationEndWhen: "Notification Close When",
        nightTime: "Night Time",
        notReadyForWork: "Not Ready For Work",
        nameTitle: "Name Title",
        newUserGroup: "New Usergroup",
        newRoute: "New Route",
        newUser: "New User",
        nearPlants: "Near Plants",
        noParking: "No Parking",
        newTicket: "New Ticket",
        nationalityZone: "Nationality Zone",
        newCustomer: "New Customer",
        notificationType: "Notification Type",
        nationality: "Nationality",
        name: "Fullname",
        namegroup: "Group Name",
        namegroup_require: "Group Name is require",
        new: "New",
        newJob: "New Job",
        no: "#",
        number: "Quantity",
        nounsCar: "Cars",
        next: "Next",
        noVehicleSelect: "No Selection",
        newLocation: "New Location",
        numberOfExpirationDates: "Number Of Expiration Dates",
        node: "Node",
        nodeName: "Node Name",
        nextRegisterDate: "Next Register Date",
        nearByLocation: "Nearby Location",
        nearbyPlants: "Nearby Plants",
        NumberofdaysworkedReport: "Number of days worked Report",
        number_of_days: "Number of days",
        no_filter: "No filter",
        numberVehiclegroup: "Quantity",
        nextMaintenanceConditions: "Next maintenance conditions",
        nameTableCompany: "Name",
        nameAE: "Name AE",
        nofilevideo: "Unable to stream video at the selected time",
        normalStatusImage: "Normal Status Image",
        notificationTracking: "Notification Tracking",
        notAvailable: "Not Available",
        no_mold: "No.",
        number_queue: "Number(queue)",
        number_mold: "Number(mold)",
    },

    o: {
        on: "on",
        off: "off",
        onDevelop: "On Develop",
        onFilter: "On",
        offFilter: "Off",
        onFilterMeter: "On",
        offFilterMeter: "Off",
        outside: "Outside",
        onProcess: "On Process",
        originToDestination: "Origin-Destination",
        ok: "Yes",
        overview: "Overview",
        other: "Other",
        obd: "OBD",
        or: "OR",
        openFilter: "Open Filter",
        overSpeedInNormalRoutes: "Speed Limited (General Route)",
        overdueSpeedInTheCommunityArea: "Speed Limited (Community Area)",
        overSpeedInTheJunction: "Speed Limited (Junction)",
        open: "Open",
        orderCode: "Order Code",
        operation: "Operation",
        ownerView: "Owner View",
        operator: "Osperator",
        online: "Online",
        offline: "Offline",
        vehicleOffline: "Vehicle is offline",
        okay: "OK",
        over_hour: "Driving over hour",
        ordinal_numbers: "Ordinal No.",
        opened: "Opened",
        onSite: "On Site",
        order_number: "Order Number",
        order_number2: "Order Number",
    },

    p: {
        pullvehiclesink: "Pulled Sink Vehicle History",
        pleaseSelectPicture: "Please select a picture.",
        position: "Position",
        preventRePinLocationConfirm: "Location will lost when re pin?",
        preventReDrawLocationConfirm: "Location will lost when re draw?",
        parking: "Parking",
        policy: "Policy",
        plantArrivingTime: "Plant Arriving Time",
        plantCode: "Plant Code",
        productType: "Product Type",
        phoneNumber: "Phone Number",
        plateNo: "Plate No.",
        pickup: "Pick Up",
        pickupData: "Pick Up Date",
        passenger: "Passenger",
        pickUpTime: "Pick Up Time",
        pickUpLocation: "Pick Up Location",
        pickUpDetail: "Pick Up Detail",
        pickUpDateTime: "Pick Up At",
        previous: "Previous",
        pinDown: "Pinned",
        partiallyDamagedCard: "Card Can Not Be Used",
        pto: "PTO",
        personal: "Personal",
        partner: "Partner",
        photo: "Photo",
        privilegegroup: "Privilege Group",
        purchaseChannel: "Purchase Channel",
        pending: "Pending",
        PONumber: "P/O Number",
        pdfFilesUpTo5MBInSize: "PDF Files Up To 5MB In Size",
        po: "PO",
        picture: "Picture",
        pictureUpdate: "Picture Update",
        plant: "Plant",
        polyGonRadius: "Polygon Radius",
        parkingLot: "Parking Lot",
        pdf: "PDF",
        password: "Password",
        pleaseSelect: "Please Select",
        personalID: "Personal ID",
        plantFactory: "Plant/Factory",
        period: "Period",
        periodOfInsuranceYear: "Period Of Insurance (Year)",
        part_type: "Part",
        ParkingReport: "Parking Report",
        preview: "Preview",
        partnerCar: "Partner",
        publicKey: "Public key",
        partType: "Part Type",
        partgroup: "Part group",
        province: "Province",
        product_type: "Product type",
        product_name: "Product name",
        plan_start_deliver_at: "Plan start deliver at",
        personalInformation: "Personal Information",
        plateNoOfUninstall: "Plate no of uninstall",
        plateNoOfInstall: "Plate no of install",
        partSetting: "Part Setting",
        processing: "Processing",
        preparePoint: "Prepare Point",
        password_require: "Password is require",
        confirm_password_require: "Confirm password is require",
        please_less_than_6: "Please Enter less then 6 letters",
        passwords_must_match: "Passwords does not match",
        password_new: "New password",
        phone: "Phone Number",
        phone_require: "Phone Number is required",
        phone_invalid: "Phone Number is invalid",
        position_require: "Position is required",
        price: "Price",
        plateNoExist: "This plateNo is duplicate with company",
        product_code: "Product Code",
    },

    q: {
        quantity_liter: "Quantity (liter)",
        quantity: "Quantity",
        quotation: "Bid Price",
        qc_name: "QC Name",
    },

    r: {
        rainingTime: "Raining Time",
        replay: "Replay",
        replayDetail: "Replay Detail",
        received: "Received",
        reportSummarizingTheNumberOfOffSiteParking:
            "Report Summarizing The Number Of Off-Site Parking",
        reportCardSwipeBehaviorBeforeDrivingDaily:
            "Report Card Swipe Behavior Before Driving Daily",
        reportType: "Report Type",
        report: "Report",
        reActivate: "Re-Activate",
        reset: "Reset",
        reject: "Reject",
        routeRadius: "Route Radius",
        routeRadiusM: "Route Radius (m.)",
        routeName: "Route Name",
        restArea: "Rest Area",
        radiuns: "Radius",
        radiunsM: "Radius (m.)",
        remark: "Comment",
        remainingRequest: "Remaining Request",
        requestList: "Request List",
        require: "Require",
        rpm: "RPM",
        running: "Running",
        remark2: "Remark 2",
        remark1: "Remark 1",
        reminderData: "Reminder Date",
        reminderTime: "Reminder Time",
        remarkBooking: "Remark Booking",
        reportAlarmFrequencyClassifiedByDailyDriver:
            "Report alarm frequency Classified by daily driver",
        route: "Route",
        rfid: "RFID",
        refuelAt: "Refuel At",
        refuel: "Refuel",
        reFuelTitle: "Refuel",
        RefuelReport: "Refuel Report",
        RiskbehaviourReport: "Risk behaviour Report",
        risk: "Risk",
        round: "Round",
        renewalDate: "Renewal Date",
        remainDay: "Remain (day)",
        remindDate: "Remind Date",
        RepairDevice: "Repair Device",
        receiveDate: "Receive Date",
        resendEmail: "Resend Email",
        report_daily: "Daily",
        report_weekly: "Weekly",
        report_monthly: "Monthly",
        report_yearly: "Yearly",
        report_name: "Report name",
        routemaster: "Route Master",
        route_master_name: "Route master name",
        rateConfirmationPeriod: "Confirmation Period",
        ratePunctuality: "Punctuality",
        ratePersonality_driver: "Personality Driver",
        rateGoodservice: "Willingness to Service",
        rateServiceQuality: "All Service Quality Rating",
        rateServiceQualityRecent: "Recent Service Quality Rating",
        row_number: "No.",
        reported_at: "Reported At",
        request_qc_onsite: "Require QC Onsite",
    },

    s: {
        start_unload_at: "Unload Start",
        section: "Section",
        searchDashCameras: "Search Text from Plate No ...",
        speedKmPerHr: "Speed (km./h)",
        safety: "Safety",
        subType: "Sub Type",
        setting: "Setting",
        shipTo: "Ship To",
        siteCode: "Site Code",
        saveNext: "Save & Next",
        send: "Send",
        save: "Save",
        search: "search",
        surplus: "Surplus",
        selected: "Selected",
        status: "Status",
        secondaryPhoneNumber: "2nd Phone Number",
        selectedVehicleAlready: "Selected Vehicle Already",
        southeastAsia: "Southeast Asia",
        submit: "Save",
        satellite: "Satellite",
        showTraffic: "Show Traffic",
        searchText: "Search Text",
        searchTextFromPlateNo: "Search Text From Plate No ...",
        speedRange: "Speed Range (km./h)",
        speedRangeNoKm: "Speed Range",
        slidingCard: "Card Swipe",
        speed: "Speed",
        support: "Support",
        someparts: "Some Parts",
        slideWrongCardType: "Invalid Card Type",
        speedLimited: "Speed Limited",
        startDate: "Start Date",
        sentBy: "Sent By",
        sender: "Sender",
        sendAsAGroupOfVehicles: "By Vehicle Group",
        sendSelectedVehicles: "By Selected Vehicle",
        sentSuccessfully: "Sent Successfully",
        sendFailure: "Send Failure",
        selectPlateNo: "Select Vehicle",
        selectAssignTo: "Select Assign To",
        selectCriticalityLevel: "Select Criticality Level",
        selectStatus: "Select Status",
        selectVehicleGroup: "Select Vehicle Group",
        selectNotificationChannel: "Select Notification Channel",
        smsNotifyTo: "SMS Notify To",
        startLocationName: "Start Location Name",
        startDestinationCode: "Start Destination Code",
        startDestinationName: "Start Destination Dame",
        setTheRoute: "Set The Route",
        startLocationCode: "Start Location Code",
        specialPrivilege: "Special Privilege",
        selectOne: "Select One",
        state: "State",
        shopee: "Shopee",
        salesChannels: "Sales Channels",
        selectFile: "Select File",
        simNumber: "SIM Number",
        startTrip: "Start Trip",
        sos: "sos",
        SCCOCarComparisonReport: "SCCO car comparison report",
        seenAll: "Seen All",
        shareTo: "Share To",
        shareFrom: "Share From",
        sharedAt: "Shared At",
        speedOverLimit: "Speed Over Limit",
        speedOverLimitByVehicleGroup: "Speed Over Limit By Vehicle Group",
        speedOverLimitByVehicleType: "Speed Over Limit By Vehicle Type",
        speedOverLimitByDriver: "Speed Over Limit By Driver",
        stopWithEngineOn: "Stop/Engine On",
        stopWithEngineOff: "Stop/Engine Off",
        startValidDate: "Start Valid Date",
        startAndEndValidDateDesc: "Vehicles Using Period",
        setup: "Set Up",
        smallTruck: "Small Truck",
        shaft: "Shaft",
        statusVehicleVideo: "Status",
        searchPlants: "Search",
        status_moving: "Moving",
        status_parked: "Parked",
        status_engineoff: "Engine off",
        status_vehicle: "Vehicle status",
        select_event: "select event",
        SpeedoverlimitsummaryReport: "Speed over limit summary Report",
        SummaryKPIReport: "Summary KPI Report",
        SummaryKPIByDriverReport: "KPI By Driver Report",
        startDeliveryAt: "Start Deliver At",
        startRefuel: "Start Refuel",
        start_daily_time: "Daily start time",
        second_dest: "End Position",
        ship_to: "Ship to",
        scan_date_at: "Uploaded Date",
        shareLocation: "Share location",
        startAt: " Start at",
        send_email: " Send email",
        Servicerepairdevice: "Repair inform",
        submit_name: "Submit name",
        submit_telno: "Submit telno",
        start_deliver_at: "Start Deliver At",
        salesMan: "Salesman",
        scheduled: "Scheduled",
        servicemovedevice: "Service Move Device",
        submitName: "Submit Name",
        submitTelno: "Submit Tel No",
        serviceinstalldevice: "Service Install Device",
        sizeAndWeight: "Size And Weight",
        selectDate: "Select date",
        selectTime: "Select time",
        summaryInfo: "Summary Information",
        status_download_file: "Status Download File",
        show: "Show",
        SummaryiconsiamReport:
            "Shuttle Boat – Shuttle Bus Service Average Summary Report",
        showAll: "Show All",
        schedulereport: "Schedule report",
        schedule_type: "Schedule type",
        setting_filter: " Filter setting",
        send_data_before_delivery: "Send data before delivery date",
        success: "Success",
        searchDirections: "Search Directions",
        signup: "Sign Up",
        signup_button: "Sign Up",
        send_verify_code: "Send verify code",
        send_verify_email: "Send verify email",
        send_verify_email_error: "Can't send email verify,Please try again",
        send_verify_code_error: "Can't verify by code,Please try again",
        send_email_forgotpassword: "Send link to email for reset password",
        send_email_forgotpassword_error:
            "Can't send email for reset password,Please try again",
        select_user_type: "Select User Type",
        select_file: "Select File",
        success_save: "Success",
        satisfactionSurvey: "Satisfaction Survey",
        signature: "Signature",
        star: "Star",
        site_code: "Site Code",
        startDateTime: "Start Date",
        structure: "Structure",
        slump_flow: "Slump Flow",
        site: "Site",
        storage_location2: "Storage Location",
    },

    t: {
        titleAccount: "Account",
        tokenExpired: "Token Expired",
        ticket: "Ticket",
        title: "Title",
        today: "Today",
        thisMonth: "This Month",
        thisYear: "This Year",
        tool: "Tool",
        tools: "Tools",
        taxiMeter: "Taxi Meter",
        temperature: "Temperature",
        temperature1: "Temp. 1",
        temperature2: "Temp. 2",
        TheUsualRoute: "The Usual Route",
        time: "Time",
        toggleHeatMap: "Toggle Heat Map",
        trackingNo: "Tracking No.",
        total: "Total",
        totalEmergencyCase: "Total Emergency Case",
        typeLocation: "Type Location",
        theRoute: "Route",
        toSiteDistance: "To Site Distance",
        timeThatCanBeUsed: "Time That Can Be Used.",
        top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod:
            "Top 5 Alert Reports Of Each Type Divided By Monthly Time Period",
        theDriverIsIicenseReportHasExpired:
            "The Driver's Iicense Report Has Expired",
        theDayThatTheCarPulled: "The Day That The Car Pulled",
        trip: "Trip",
        tripStatus: "Trip Status",
        topCustomer: "Top Customer",
        topEarning: "Top Earning",
        type: "Type",
        tracking: "Tracking",
        traininglist: "Training List",
        trainingDate: "Training Date",
        trainer: "Trainer",
        tagID: "Tag ID",
        truckCategory: "Truck Category",
        truckType: "Truck Type",
        tire: "Tire",
        truckDriverMatching: "Truck Driver Matching",
        TruckusageReport: "Truck Usage Report",
        totalPrice_baht: "Total Price (Baht)",
        totalPrice: "Total Price",
        TemperatureReport: "Temperature Report",
        time_15: "15 mins",
        time_30: "30 mins",
        time_60: "1 hr",
        time_180: "3 hrs",
        time_300: "5 hrs",
        time_420: "7 hrs",
        time_720: "12 hrs",
        time_1440: "24 hrs",
        TrackingdataReport: "Tracking Data Report",
        type_report: "Type Report",
        type_trip: "By Trip",
        type_person: "By Person",
        type_duration: "By Duration",
        TriptemperatureReport: "Trip Temperature Report",
        TripsumbyvehReport: "Trip Summary Seperated By Vehicle Report",
        TruckengineonReport: "Truck engine on Report",
        TruckengineoffReport: "Truck engine off Report",
        TrucknotuseReport: "Truck not use Report",
        tripCode: "Trip Code",
        TripsummaryReport: "Trip Summary Report",
        TripsumbylocationReport: "Trip Summary By Location Report",
        trip_report_menu: "Trip",
        typeMaintenance: "Type",
        technician: "Technician",
        TruckcurrentlocationReport: "Current Address Report",
        time_period: "Time period",
        type_problem: "Type problem",
        trip_date: "Duration date trip",
        trip_duration: "Start at - End at",
        taxNo: "Tax No.",
        timeZone: "Time Zone",
        telnoAE: "Telno AE",
        TripbyengineoffonReport: "Trip By Engine On - Off Report",
        totalDistance: "Total Distance (km/h)",
        totalDuration: "Total Duration (Day:Hour:Minutes)",
        to_email: "To",
        to: " To ",
        time_entry: "Entry time",
        time_out: "Out time",
        time_use_in_location: "Time in location",
        time_travel: "Travel time",
        thisWorkSheetisAlreadyhaveRating: "This Worksheet is Already have Rating",
        transporterConcreteMold: "Transporter Concrete Mold",
        type_end_location: "Type End Location",
        type_start_location: "Type Start Location",
        totalAssign: "Total Assignment",
        totalPlantCheckin: "Total Plant Checkin",
        totalSiteCheckin: "Total Site Checkin",
        totalMold: "Total Mold",
        typeWork: "Type of Work",
        ticket_id: "DP Number",
    },

    u: {
        userClose: "User Closed",
        updatedAt: "Updated At",
        userProfile: "User Profile",
        unknown: "Unknown",
        userGroupPremission: "User Group Premision",
        updatePictures: "Update A Photo",
        updateLocation: "Update A Location",
        unassign: "Unassign",
        updatedBy: "Modified By",
        userName: "Username",
        user: "User",
        userGroup: "Usergroup",
        unMatch: "Un Match",
        upload: "Upload",
        userExpire: "User Expire",
        unitPrice: "Unit Price (Baht)",
        use_date_time: "Action date time",
        use_account_name: "Account name",
        uninstall: "Uninstall",
        unBroken: "Unbroken",
        unit: "Unit",
        update_success: "Update Success",
        update_fail: "Update Fail",
        upload_amount1_support: "File max amount 1 file",
        upload_amount5_support: "File max amount 5 files",
        user_type: "User Type",
        user_code: "User Code",
    },

    v: {
        version: "Version",
        vnCode: "VN Code",
        video: "MDVR Live Stream",
        vehicleModel: "Vehicle Model",
        vehicleColor: "Vehicle Color",
        vehicleType: "Vehicle Type",
        vehicle: "Vehicle",
        vehicleID: "Vehicle ID",
        vehicleStatus: "Vehicle Status",
        view: "View",
        vehicleDetail: "Vehicle Detail",
        vehicleGroupName: "Vehicle Group Name",
        vehicleGroup: "Vehicle Group",
        vehicleSharing: "Vehicle Sharing",
        validDate: "Valid Date",
        vehicleRegisterType: "Vehicle Register Type",
        VelocitysummaryReport: "Velocity summary Report",
        vehicleCode: "Vehicle Code",
        VehicleperformanceconcludeReport: "Vehicle Performance Conclude Report",
        VehicleinareaReport: "Vehicle in area Report",
        video_type: "Video type",
        vehicle_type: "Vehicle type",
        voluntary: "Voluntary",
        VehicleinformationdltReport: "Vehicle Information For DLT",
        vendor: "Vendor",
        verifyYourEmailAddress: "Verify Your Email Address",
        videoDownload: "Video Download",
        vehicleAndDriver: "Vehicle And Driver",
        verify_signup: "Verify code",
        verify_code_require: "Verify code is require",
        verify_code_digit: "6 digit code",
        verify: "Verify",
        verify_yes: "Verified",
        verify_no: "Not Verified",
        vehicle_verify: "Vehicle Verify",
        verify_status: "Verify Status",
        validate_upload_file: "You can only upload JPG/PNG/PDF file",
        validate_file_size: "Image must smaller than 5MB",
        validate_id_card: "Incorrect ID card no.",
        validate_id_card_dup: "Duplicate ID card",
        validate_id_card_length: "ID card must be exactly 13 characters",
        validate_code_dup: "Duplicate Driver code",
        validate_phone_dup: "Duplicate Phone Number",
        verify_code:
            "Enter the OTP you received SMS for Login(OTP is valid for 2 minutes).",
        validate_phone: "Phone number must be exactly 10 characters",
        validate_number: "Phone number must be number only",
        validate_required_data: "Data is incomplete. please fill information",
        vehicle_code: "Vehicle Code",
    },

    w: {
        week: "Week",
        weekly: "Weekly",
        warrantyEnd: "Warranty End",
        workOverTime: "Overtime",
        waitingConfirm: "Waiting Confirm",
        wigth: "Wigth",
        wheel: "Wheel",
        waitingForJob: "Waiting",
        waitAssessment: "Wait Assessment",
        whichMayHaveToContactTheAppointmentLocationAgain:
            "Which may have to contact the appointment location again.",
        weWillContactYouAtTheTelephoneNumberProvided:
            "We will contact you at the telephone number provided.",
        waitForEmailChecking: "Wait For Email Checking",
        w_sunday: "Sunday",
        w_monday: "Monday",
        w_tuesday: "Tuesday",
        w_wednesday: "Wednesday",
        w_thursday: "Thursday",
        w_friday: "Friday",
        w_saturday: "Saturday",
        workSheetIdNotExist: "This Worksheet is Not Exist",
        workingAt: "Date",
    },

    x: {},

    y: {
        youCanOonlyUploadPdfFile: "You Can Only Upload PDF File!",
        yearly: "Yearly",
        year: "Year",
        youCanOnlyUploadJPGPNGPDFFile: "You can only upload JPG/PNG/PDF file!",
        youHaveNoRightToEvaluateThisWorksheet:
            "You have no right to evaluate this worksheet.",
    },

    z: {
        zoomIn: "Zoom in",
        zoomOut: "Zoom out",
        zone: "Zone",
        zoneName: "Zone Name",
        zoomInForMoreInfo: "Zoom in for use Feature",
        zoneNameIsRequired: "Zone Name is Required",
    },
};

export { en };
