// etc
import watchUIState from "modules/ui/sagas";
import watchAuthState from "modules/auth/sagas";

// master data
import watchPermissionMasterDataState from "modules/master-data/permission/sagas";
import watchUserMasterData from "modules/master-data/user/sagas";
import watchUsergroupMasterDataState from "modules/master-data/usergroup/sagas";
import watchConcreteCollector from "modules/master-data/concrete-collector/sagas"

import watchFormWork from "modules/formwork/sagas"

import watchQCMangement from "modules/qc-management/sagas"
import watchTracking from "modules/tracking/sagas"


import { fork, all } from "redux-saga/effects";

export default function* rootSagas() {
  yield all([
    fork(watchUIState),
    fork(watchAuthState),
    fork(watchPermissionMasterDataState),
    fork(watchUserMasterData),
    fork(watchUsergroupMasterDataState),
    fork(watchConcreteCollector),
    ...watchFormWork,
    ...watchQCMangement,
    ...watchTracking
  ]);
}
