import React, { Component } from "react";
import { hot } from "react-hot-loader";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import history from "lib/history";
import configureStore from "modules/configureStore";
import Layout from "modules/ui/components/Layout";
import { ErrorBoundary } from "./modules/ui/components/ErrorBoundary";
import './App.less';

const store = configureStore();

class App extends Component {
  render() {
    return (
      <ErrorBoundary>
        <Provider store={store}>
          <Router history={history}>
            <Layout />
          </Router>
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default hot(module)(App);
