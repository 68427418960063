import React from "react";
import { Row, Col, Typography } from "antd";
import Breadcrumb from "modules/ui/components/Breadcrumb";

import { StyledContent } from "styled/cpac-qc-styled";
import { translate } from "lib/helper";

const { Title } = Typography;

const cssStyle = {
  margin: "0px 16px",
  padding: 24,
  minHeight: 280,
};

const ContentLayout = (props) => {
  let value = {
    title: "",
    breadcrumb: [],
    menu: "",
    matching_key: "",
  };

  let currentUrl = window.location.pathname.replace(/\/\d+/g, "").split("/");
  props.children.map((x) => {
    const { path } = x.props.value;
    let rx = path.replace(/\//g, "");
    if (currentUrl[1] === rx) return (value = x.props.value);
    return false;
  });

  const { title, breadcrumb, group } = value;

  return (
    <div style={{
        width:'100%',
        paddingBottom:"10px"
      }}>
      <Row gutter={24} style={{ margin: "24px 16px" }}>
        <Col span={24}>
          <Breadcrumb items={breadcrumb} group={group} {...props} />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ margin: "0px 16px" }}>
        <Col span={24}>
          <Title level={3}>
            {currentUrl.length >= 3 && translate(currentUrl[2], "titleaction")}
            {currentUrl.length < 3 && translate(title, "sidebar")}
          </Title>
        </Col>
      </Row>
      <StyledContent style={cssStyle}>{props.children}</StyledContent>
    </div>
  );
};

export default ContentLayout;
