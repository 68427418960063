import { createTypes, createAction } from "lib/action";

const LOAD_QC = createTypes("loadQC", "load");
const SHOW_QC = createTypes("showQC", "show");
const CREATE_QC = createTypes("createQC", "create");
const UPDATE_QC = createTypes("updateQC", "update");
const DELETE_QC = createTypes("deleteQC", "delete");
const IMPORT_QC = createTypes("importQC", "import");
const EXPORT_QC = createTypes("exportQC", "export");

const loadQC = {
    request: (params, token) => createAction(LOAD_QC.REQUEST, { params, token }),
    success: data => createAction(LOAD_QC.SUCCESS, { data }),
    failure: err => createAction(LOAD_QC.FAILURE, { err }),
};

const showQC = {
    request: (id, token) => createAction(SHOW_QC.REQUEST, { id, token }),
    success: data => createAction(SHOW_QC.SUCCESS, { data }),
    failure: err => createAction(SHOW_QC.FAILURE, { err }),
};

const createQC = {
    request: (params, token) => createAction(CREATE_QC.REQUEST, { params, token }),
    success: data => createAction(CREATE_QC.SUCCESS, { data }),
    failure: err => createAction(CREATE_QC.FAILURE, { err }),
};

const updateQC = {
    request: (params, token) => createAction(UPDATE_QC.REQUEST, { params, token }),
    success: data => createAction(UPDATE_QC.SUCCESS, { data }),
    failure: err => createAction(UPDATE_QC.FAILURE, { err }),
};

const deletedQC = {
    request: (id, token) => createAction(DELETE_QC.REQUEST, { id, token }),
    success: id => createAction(DELETE_QC.SUCCESS, { id }),
    failure: err => createAction(DELETE_QC.FAILURE, { err }),
};

const importQC = {
    request: (file, user_id, token) => createAction(IMPORT_QC.REQUEST, { file, user_id, token }),
    success: data => createAction(IMPORT_QC.SUCCESS, { data }),
    failure: err => createAction(IMPORT_QC.FAILURE, { err }),
}


const exportQC = {
    request: (params, token) => createAction(EXPORT_QC.REQUEST, { params, token }),
    success: data => createAction(EXPORT_QC.SUCCESS, { data }),
    failure: err => createAction(EXPORT_QC.FAILURE, { err }),
}


export {
    loadQC,
    showQC,
    createQC,
    updateQC,
    deletedQC,
    importQC,
    exportQC,
    LOAD_QC,
    SHOW_QC,
    CREATE_QC,
    UPDATE_QC,
    DELETE_QC,
    IMPORT_QC,
    EXPORT_QC
};
