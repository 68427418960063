import {
  LOAD_TRANSFER_MOLD,
  TRANSFER_CONCRETE_MOLD,
} from "./actions";

const msg = {
  success_msg: "",
  err_msg: "",
};

const initialState = {
  lists: [],
  total_rows: 0,
  loading: false,
  shouldReload: false,
  data: [],
  ...msg,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRANSFER_MOLD.REQUEST:
    case TRANSFER_CONCRETE_MOLD.REQUEST:
      return {
        ...state,
        ...msg,
        loading: true,
        shouldReload: false,
      };

    case LOAD_TRANSFER_MOLD.SUCCESS:
      return {
        ...state,
        lists: action.payload.data.data,
        total_rows: action.payload.data.total,
        loading: false,
      };
    
    case TRANSFER_CONCRETE_MOLD.SUCCESS:
    return {
      ...state,
      success_msg: "save_success",
      loading: false,
    };

    case LOAD_TRANSFER_MOLD.FAILURE:
    case TRANSFER_CONCRETE_MOLD.FAILURE:
      return {
        ...state,
        err_msg: action.payload.err,
        loading: false,
        shouldReload: false,
      };

    default:
      return state;
  }
};
