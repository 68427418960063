import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadData(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post("supervisor", params, token);
    yield put(actions.loadData.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* showData(action) {
  try {
    const { id, token } = action.payload;
    const res = yield fetcher.get(`supervisor/detail/${id}`, token);
    yield put(actions.showData.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* createData(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post(`supervisor/create`, params, token);
    yield put(actions.createData.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.createData.failure(getErrorMsg(error)));
  }
}

function* updateData(action) {
  const { params, token } = action.payload;

  try {
    const res = yield fetcher.post(`supervisor/edit`, params, token);
    yield put(actions.updateData.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.updateData.failure(getErrorMsg(error), params));
  }
}

function* deleteData(action) {
  try {
    const { id, token } = action.payload;
    yield fetcher.delete(`supervisor/delete-data/${id}`, token);
    yield put(actions.deleteData.success(id));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteData.failure(getErrorMsg(error)));
  }
}

function* deleteItem(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post(`supervisor/delete-item`, params, token);
    yield put(actions.deleteItem.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteItem.failure(getErrorMsg(error)));
  }
}

function* exportData(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post(`supervisor/export`, params, token);
    yield put(actions.exportData.success(res.data));
    fetcher.download(res.data.data.replace(/\//g, "."));
  } catch (error) {
    handleError(error);
    yield put(actions.exportData.failure(getErrorMsg(error)));
  }
}

function* importData(action) {
  try {
    const { file, user_id, token } = action.payload;
    const formData = new FormData();
    formData.append("file", file.originFileObj);
    formData.append("user_id", user_id);
    const res = yield fetcher.upload(`supervisor/import`, formData, token);
    yield put(actions.importData.success(res.data));
    yield put(push("/supervisor"));
  } catch (error) {
    handleError(error);
    yield put(actions.importData.failure(getErrorMsg(error)));
  }
}

export default function* watchQcJob() {
  yield all([
    takeEvery(actions.LOAD_DATA.REQUEST, loadData),
    takeEvery(actions.SHOW_DATA.REQUEST, showData),
    takeEvery(actions.CREATE_DATA.REQUEST, createData),
    takeEvery(actions.UPDATE_DATA.REQUEST, updateData),
    takeEvery(actions.DELETE_DATA.REQUEST, deleteData),
    takeEvery(actions.DELETE_ITEM.REQUEST, deleteItem),
    takeEvery(actions.EXPORT_DATA.REQUEST, exportData),
    takeEvery(actions.IMPORT_DATA.REQUEST, importData),
  ]);
}
