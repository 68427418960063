import { createTypes, createAction } from "lib/action";

const LOAD_CONCRETE_COLLECTOR = createTypes("concreteCollector", "load");
const SHOW_CONCRETE_COLLECTOR = createTypes("concreteCollector", "show");
const CREATE_CONCRETE_COLLECTOR = createTypes("concreteCollector", "create");
const UPDATE_CONCRETE_COLLECTOR = createTypes("concreteCollector", "update");
const DELETE_CONCRETE_COLLECTOR = createTypes("concreteCollector", "delete");

const loadConcreteCollector = {
  request: (params, token) => createAction(LOAD_CONCRETE_COLLECTOR.REQUEST, { params, token }),
  success: data => createAction(LOAD_CONCRETE_COLLECTOR.SUCCESS, { data }),
  failure: err => createAction(LOAD_CONCRETE_COLLECTOR.FAILURE, { err }),
};

const showConcretetCollector = {
  request: (id, token) => createAction(SHOW_CONCRETE_COLLECTOR.REQUEST, { id, token }),
  success: data => createAction(SHOW_CONCRETE_COLLECTOR.SUCCESS, { data }),
  failure: err => createAction(SHOW_CONCRETE_COLLECTOR.FAILURE, { err }),
};

const createConcretetCollector = {
  request: (params, token) => createAction(CREATE_CONCRETE_COLLECTOR.REQUEST, { params, token }),
  success: data => createAction(CREATE_CONCRETE_COLLECTOR.SUCCESS, { data }),
  failure: err => createAction(CREATE_CONCRETE_COLLECTOR.FAILURE, { err }),
};

const updateConcretetCollector = {
  request: (params, token) => createAction(UPDATE_CONCRETE_COLLECTOR.REQUEST, { params, token }),
  success: data => createAction(UPDATE_CONCRETE_COLLECTOR.SUCCESS, { data }),
  failure: err => createAction(UPDATE_CONCRETE_COLLECTOR.FAILURE, { err }),
};

const deleteConcretetCollector = {
  request: (id, token) => createAction(DELETE_CONCRETE_COLLECTOR.REQUEST, { id, token }),
  success: id => createAction(DELETE_CONCRETE_COLLECTOR.SUCCESS,  { id } ),
  failure: err => createAction(DELETE_CONCRETE_COLLECTOR.FAILURE, { err }),
};

export {
  loadConcreteCollector,
  showConcretetCollector,
  createConcretetCollector,
  updateConcretetCollector,
  deleteConcretetCollector,
  LOAD_CONCRETE_COLLECTOR,
  SHOW_CONCRETE_COLLECTOR,
  CREATE_CONCRETE_COLLECTOR,
  UPDATE_CONCRETE_COLLECTOR,
  DELETE_CONCRETE_COLLECTOR,
};
