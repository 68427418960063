import { createTypes, createAction } from "lib/action";

const LOAD_WORKLIST = createTypes("worklist", "load");
const SHOW_WORKLIST = createTypes("worklist", "show");
const CREATE_WORKLIST = createTypes("worklist", "create");
const UPDATE_WORKLIST = createTypes("worklist", "update");
const DELETE_WORKLIST = createTypes("worklist", "delete");
const IMPORT_WORKLIST = createTypes("worklist", "import");

const loadWorklist = {
  request: (params, token) => createAction(LOAD_WORKLIST.REQUEST, { params, token }),
  success: data => createAction(LOAD_WORKLIST.SUCCESS, { data }),
  failure: err => createAction(LOAD_WORKLIST.FAILURE, { err }),
};

const showWorklist = {
  request: (id, token) => createAction(SHOW_WORKLIST.REQUEST, { id, token }),
  success: data => createAction(SHOW_WORKLIST.SUCCESS, { data }),
  failure: err => createAction(SHOW_WORKLIST.FAILURE, { err }),
};

const createWorklist = {
    request: (params, token) => createAction(CREATE_WORKLIST.REQUEST, { params, token }),
    success: data => createAction(CREATE_WORKLIST.SUCCESS, { data }),
    failure: err => createAction(CREATE_WORKLIST.FAILURE, { err }),
  };

const updateWorklist = {
  request: (params, token) => createAction(UPDATE_WORKLIST.REQUEST, { params, token }),
  success: data => createAction(UPDATE_WORKLIST.SUCCESS, { data }),
  failure: (err, params) => createAction(UPDATE_WORKLIST.FAILURE, { err, params }),
};

const deletedWorklist = {
  request: (id, token) => createAction(DELETE_WORKLIST.REQUEST, { id, token }),
  success: id => createAction(DELETE_WORKLIST.SUCCESS,  { id } ),
  failure: err => createAction(DELETE_WORKLIST.FAILURE, { err }),
};

const importWorklist = {
    request: (file, company_id, user_id, token) => createAction(IMPORT_WORKLIST.REQUEST, { file, company_id, user_id, token }),
    success: data => createAction(IMPORT_WORKLIST.SUCCESS,  { data } ),
    failure: err => createAction(IMPORT_WORKLIST.FAILURE, { err }),
}

export {
  loadWorklist,
  showWorklist,
  createWorklist,
  updateWorklist,
  deletedWorklist,
  importWorklist,
  LOAD_WORKLIST,
  SHOW_WORKLIST,
  CREATE_WORKLIST,
  UPDATE_WORKLIST,
  DELETE_WORKLIST,
  IMPORT_WORKLIST
};
