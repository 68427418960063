import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb as AntBreadcrumb } from "antd";
import { translate } from "lib/helper";

import { HomeOutlined } from "@ant-design/icons";

const generateItem = (items) => {
  if (typeof items === "undefined") return;
  if (items.length > 0) {
    let key = window.location.pathname.replace(/\//, "").replace(/\/\d+/g, "")
    return items[0][key].map((val, i) => {
      let lang = val.name.split(".")
      return (
        <AntBreadcrumb.Item key={i}>
          { val.url !== null ? 
          <Link data-cy={`${val.url}`} to={`${val.url}`}>{translate(lang[1], lang[0])}</Link> : translate(lang[1], lang[0])}
        </AntBreadcrumb.Item>
      );
    });
  } else {
    const currentUrl = window.location.pathname.replace(/\/\d+/g, "").split("/");
    let url = "";
    return currentUrl.map((name, i) => {
      if (name === "") return null;
      url += `/${name}`;
      let nameKey = name.replace(/-/g, "_")
      let key = name.match(/create$|edit|detail/g) ? "action" : "sidebar";

      if (currentUrl.length - 1 === i) {
        return (
          <AntBreadcrumb.Item key={i}>
            {translate(nameKey, key)}
          </AntBreadcrumb.Item>
        );
      } else {
        return (
          <AntBreadcrumb.Item key={i}>
            <Link data-cy={`${url}`} to={`${url}`}>{translate(nameKey, "sidebar")}</Link>
          </AntBreadcrumb.Item>
        );
      }
    });
  }
};

const Breadcrumb = ({ match, ...props }) => {
  const { items, group } = props;

  return (
    <AntBreadcrumb {...props}>
      <AntBreadcrumb.Item key="breadcrumb-main">
        <HomeOutlined /> &nbsp;&nbsp;{translate(group, "sidebar")}
      </AntBreadcrumb.Item>
      {generateItem(items)}
    </AntBreadcrumb>
  );
};
export default Breadcrumb;
