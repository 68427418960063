import {
  LOAD_WORKLIST,
  SHOW_WORKLIST,
  CREATE_WORKLIST,
  UPDATE_WORKLIST,
  DELETE_WORKLIST,
  IMPORT_WORKLIST,
} from "./actions";

const msg = {
  success_msg: "",
  err_msg: "",
};

const data = {
  plant_id: "",
  plant_name: "",
  date_start_at: "",
  working_at: "",
  date_end_at: "",
  completed_at: "",
  order_number: "",
  comp_id: "",
  cpac_saleorder_id: "",

  id: "",
  order_status_id: 1,
  site_id: "",
  site_name: "",
  sold_to_id: "",
  concrete_mold_id: "",
  concrete_mold_qty: "",
  booking_qty: "",
  qc_id: [],
  qc_has_routine: [],
  user_id: "",
  company_id: "",
  booking_at: "",
  unloaded_at: "",
  product_id: "",
  unload_type_id: "",
  structure_id: "",
  remark: "",
  structure_other: "",
  test_location: "site",
  test_type: "cpac",
  collect_concrete: "Y",
  plant_qc_id: "",
  qc_qty: "",
  section_id: 0,
  concrete_collection: [],
  request_qc_onsite: "Y",
  product_code: "",
  product_name: ""
};

const initialState = {
  lists: [],
  total_rows: 0,
  shouldReload: false,
  loading: false,
  data: data,
  ...msg,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_WORKLIST.REQUEST:
    case SHOW_WORKLIST.REQUEST:
    case CREATE_WORKLIST.REQUEST:
    case DELETE_WORKLIST.REQUEST:
      return {
        ...initialState,
        ...msg,
        loading: true,
        shouldReload: false,
      };

    case UPDATE_WORKLIST.REQUEST:
      return {
        ...initialState,
        ...msg,
        loading: true,
        data: action.payload.params,
        shouldReload: false,
      };

    case IMPORT_WORKLIST.REQUEST:
      return {
        ...state,
        ...msg,
        loading: true,
        shouldReload: false,
      };

    case LOAD_WORKLIST.SUCCESS:
      return {
        ...state,
        lists: action.payload.data.data,
        total_rows: action.payload.data.total,
        loading: false,
      };

    case SHOW_WORKLIST.SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        loading: false,
      };

    case CREATE_WORKLIST.SUCCESS:
      return {
        ...state,
        success_msg: "save_success",
        loading: false,
      };
    case UPDATE_WORKLIST.SUCCESS:
      return {
        ...state,
        success_msg: "edit_success",
        loading: false,
      };

    case DELETE_WORKLIST.SUCCESS:
      return {
        ...state,
        success_msg: "delete_success",
        lists: state.lists.filter((x) => x.id !== action.payload.id),
        loading: false,
        shouldReload: true,
      };

    case IMPORT_WORKLIST.SUCCESS:
      return {
        ...state,
        success_msg: "import_success",
        loading: false,
        shouldReload: true,
      };

    case LOAD_WORKLIST.FAILURE:
    case SHOW_WORKLIST.FAILURE:
    case CREATE_WORKLIST.FAILURE:
    case DELETE_WORKLIST.FAILURE:
    case IMPORT_WORKLIST.FAILURE:
      return {
        ...state,
        err_msg: action.payload.err,
        loading: false,
        shouldReload: false,
      };
    case UPDATE_WORKLIST.FAILURE:
      return {
        ...state,
        err_msg: action.payload.err,
        loading: false,
        data: action.payload.params,
        shouldReload: false,
      };

    default:
      return state;
  }
};
