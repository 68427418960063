import {
    LOAD_DATA,
    CREATE_DATA,
    SHOW_DATA,
    UPDATE_DATA,
    DELETE_DATA,
    DELETE_ITEM,
    EXPORT_DATA,
    IMPORT_DATA,
} from "./actions";

const msg = {
    success_msg: "",
    err_msg: "",
};

const data = {
    id: "",
    total: "",
    data: {
        qc_id: [],
        division_id: "",
        department_id: [],
        section_id: [],
        qc: [],
        plant_id: [],
        username: "",
        password: "",
        firstname: "",
        lastname: "",
        supervisor_division_id: "",
        supervisor_department_id: [],
        supervisor_section_id: [],
        supervisor_plant_id: [],
    },
};

const initialState = {
    lists: [],
    total_rows: data.total,
    shouldReload: false,
    loading: false,
    data: data.data,
    ...msg,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_DATA.REQUEST:
        case SHOW_DATA.REQUEST:
        case CREATE_DATA.REQUEST:
        case DELETE_DATA.REQUEST:
        case DELETE_ITEM.REQUEST:
        case EXPORT_DATA.REQUEST:
        case IMPORT_DATA.REQUEST:
            return {
                ...initialState,
                ...msg,
                loading: true,
                shouldReload: false,
            };

        case UPDATE_DATA.REQUEST:
            return {
                ...initialState,
                ...msg,
                loading: true,
                data: action.payload.params,
                shouldReload: false,
            };

        case LOAD_DATA.SUCCESS:
            return {
                ...state,
                lists: action.payload.data.data,
                total_rows: action.payload.data.total,
                loading: false,
            };

        case SHOW_DATA.SUCCESS:
            return {
                ...state,
                data: action.payload.data.data,
                loading: false,
            };

        case CREATE_DATA.SUCCESS:
            return {
                ...state,
                success_msg: "save_success",
                loading: false,
            };

        case UPDATE_DATA.SUCCESS:
            return {
                ...state,
                success_msg: "edit_success",
                loading: false,
            };

        case DELETE_DATA.SUCCESS:
        case DELETE_ITEM.SUCCESS:
            return {
                ...state,
                success_msg: "delete_success",
                lists: state.lists.filter((x) => x.id !== action.payload.id),
                loading: false,
                shouldReload: true,
            };

        case EXPORT_DATA.SUCCESS:
            return {
                ...state,
                success_msg: "export_success",
                shouldReload: true,
                loading: false,
            };

        case IMPORT_DATA.SUCCESS:
            return {
                ...state,
                success_msg: "import_success",
                loading: false,
                shouldReload: true,
            };

        case LOAD_DATA.FAILURE:
        case SHOW_DATA.FAILURE:
        case CREATE_DATA.FAILURE:
        case DELETE_DATA.FAILURE:
        case DELETE_ITEM.FAILURE:
        case EXPORT_DATA.FAILURE:
        case IMPORT_DATA.FAILURE:
            return {
                ...state,
                err_msg: action.payload.err,
                loading: false,
                shouldReload: false,
            };

        case UPDATE_DATA.FAILURE:
            return {
                ...state,
                err_msg: action.payload.err,
                loading: false,
                data: action.payload.params,
                shouldReload: false,
            };

        default:
            return state;
    }
};
