import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Col, Row, Card, Spin, message } from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Formik } from "formik";
import * as yup from "yup";
import { LoginBackground } from "styled/common-styled";
import { checkLogin } from "../actions";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
};

class Login extends PureComponent {
  state = {
    username: "",
    password: "",
  };

  render() {
    let alert =
      this.props.auth.status === "error"
        ? message.error(this.props.auth.msg)
        : null;

    return (
      <LoginBackground>
        <Spin spinning={this.props.auth.loading}>
          {alert}
          {/* <div style={{ position: "relative", top: "20%" }}> */}
            <Row style={{height:'100vh'}}>
              <Col lg={12} xs={24} 
                style={{
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center"
                }}
              >
                <img 
                  src="/img/smartquality2-web.png"  
                  alt="logo img" 
                  width="80%" 
                  height="auto"
                />
              </Col>
              <Col lg={12} xs={24} 
                style={{
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    background:"#ffffff"
                }}>
                <div style={{ width:"80%" }}> 
                  <Card
                    id="title-account-login"
                    title="Account Login"
                    style={{ width: "100%" }}
                    headStyle={{ color: "#2098e2", borderBottom: "none",textAlign:"center", fontSize: "36px" }}
                    bordered={false}
                  >
                    <Formik
                      enableReinitialize={true}
                      initialValues={this.state}
                      validationSchema={yup.object().shape({
                        username: yup.string().required(" Username is required"),
                        password: yup.string().required(" Password is required"),
                      })}
                      onSubmit={(values) => {
                        this.props.checkLogin(values);
                      }}
                      render={({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                      }) => (
                        <Form
                          {...layout}
                          layout="vertical"
                          name="basic"
                          initialValues={{ remember: true }}
                          onFinish={handleSubmit}
                        >
                          <Form.Item
                            // label="Username"
                            size="large"
                            name="username"
                            required={true}
                            validateStatus={
                              touched.username && errors.username
                                ? "error"
                                : undefined
                            }
                            help={
                              touched.username && errors.username
                                ? errors.username
                                : undefined
                            }
                          >
                            <Input
                              name="username"
                              values={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              prefix={<UserOutlined />}
                              placeholder="Uername"
                              size="large"
                            />
                          </Form.Item>

                          <Form.Item
                            // label="Password"
                            name="password"
                            required={true}
                            validateStatus={
                              touched.password && errors.password
                                ? "error"
                                : undefined
                            }
                            help={
                              touched.password && errors.password
                                ? errors.password
                                : undefined
                            }
                          >
                            <Input.Password
                              type="password"
                              name="password"
                              values={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              prefix={<LockOutlined />}
                              placeholder="Password"
                              size="large"
                            />
                          </Form.Item>

                          <Form.Item>
                            <Button 
                              type="primary" 
                              htmlType="submit" 
                              style={{
                                borderRadius:'21px',
                                background:'#2098e2'
                              }} 
                              block
                            >
                              LOGIN
                            </Button>
                          </Form.Item>
                        </Form>
                      )}
                    />
                  </Card>
                    </div>
              </Col>
            </Row>
          </Spin>
        {/* </div> */}
      </LoginBackground>
    );
  }
}

export default connect(({ profile, auth }) => ({ profile, auth }), {
  checkLogin: checkLogin.request,
})(Login);
