import { createTypes, createAction } from "lib/action";
const BASE_ACTION = "app/auth";

const CHECK_LOGIN = createTypes("login", "check");
const LOAD_CREDENTIALS = `${BASE_ACTION}/credentials/load`;
const REMOVE_CREDENTIALS = `${BASE_ACTION}/credentials/remove`;

const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  },
};

const checkLogin = {
  request: (credentials, loading) =>
    createAction(CHECK_LOGIN.REQUEST, { credentials, loading }),
  success: (
    token,
    tokenPayload,
    permissions,
    vehicle_list,
    location_list,
    vehicle_group_list,
    divisions,
    departments,
    sections,
  ) =>
    createAction(CHECK_LOGIN.SUCCESS, {
      token,
      tokenPayload,
      permissions,
      vehicle_list,
      location_list,
      vehicle_group_list,
      divisions,
      departments,
      sections,
    }),
  failure: (status, msg, path_signup) =>
    createAction(CHECK_LOGIN.FAILURE, { status, msg, path_signup }),
};

const loadCredentials = () => {
  const token = localStorage.getItem("access-token");
  const profile = JSON.parse(localStorage.getItem("profile") || "{}");

  let currentTime = null;
  let tokenExp = null;
  let done = false;
  let msg = "";

  if (JSON.stringify(profile) !== "{}") {
    done = true;
    tokenExp = profile.exp;

    currentTime = Math.floor(Date.now() / 1000);
    if (currentTime > tokenExp) {
      done = false;
      msg = "Session Expired";
    }
  }

  return {
    type: LOAD_CREDENTIALS,
    payload: { token, tokenExp, profile, done, msg },
  };
};

const removeCredentials = () => {
  localStorage.removeItem("access-token");
  localStorage.removeItem("profile");
  return {
    type: REMOVE_CREDENTIALS,
  };
};

const storeCredentials = (
  token,
  profile,
  permissions,
  vehicle_list,
  location_list,
  vehicle_group_list,
  divisions,
  departments,
  sections,
) => {
  return asyncLocalStorage.setItem("access-token", token).then(function () {
    profile = {
      ...profile,
      tokenExp: profile.exp,
      permissions,
      vehicle_visibility: vehicle_list,
      location_visibility: location_list,
      vehicle_group_visibility: vehicle_group_list,
      divisions,
      departments,
      sections,
    };

    return asyncLocalStorage
      .setItem("profile", JSON.stringify(profile))
      .then(function (value) {
        return loadCredentials();
      });
  });
};

export {
  CHECK_LOGIN,
  LOAD_CREDENTIALS,
  REMOVE_CREDENTIALS,
  checkLogin,
  loadCredentials,
  removeCredentials,
  storeCredentials,
};
