import { createTypes, createAction } from "lib/action";

const LOAD_QC_DAYOFF = createTypes("dayoff", "load");
const SHOW_QC_DAYOFF = createTypes("dayoff", "show");
const CREATE_QC_DAYOFF = createTypes("dayoff", "create");
const UPDATE_QC_DAYOFF = createTypes("dayoff", "update");
const DELETE_QC_DAYOFF = createTypes("dayoff", "delete");
const IMPORT_QC_DAYOFF = createTypes("dayoff", "import");
const DOWNLOAD_QC_DAYOFF = createTypes("dayoff", "download");

const loadQcDayoff = {
  request: (params, token) =>
    createAction(LOAD_QC_DAYOFF.REQUEST, { params, token }),
  success: (data) => createAction(LOAD_QC_DAYOFF.SUCCESS, { data }),
  failure: (err) => createAction(LOAD_QC_DAYOFF.FAILURE, { err }),
};

const createQcDayoff = {
  request: (params, token) =>
    createAction(CREATE_QC_DAYOFF.REQUEST, { params, token }),
  success: (data) => createAction(CREATE_QC_DAYOFF.SUCCESS, { data }),
  failure: (err) => createAction(CREATE_QC_DAYOFF.FAILURE, { err }),
};

const showQcDayoff = {
  request: (id, token) => createAction(SHOW_QC_DAYOFF.REQUEST, { id, token }),
  success: (data) => createAction(SHOW_QC_DAYOFF.SUCCESS, { data }),
  failure: (err) => createAction(SHOW_QC_DAYOFF.FAILURE, { err }),
};

const updateQcDayoff = {
  request: (params, token) =>
    createAction(UPDATE_QC_DAYOFF.REQUEST, { params, token }),
  success: (data) => createAction(UPDATE_QC_DAYOFF.SUCCESS, { data }),
  failure: (err) => createAction(UPDATE_QC_DAYOFF.FAILURE, { err }),
};

const deletedQcDayoff = {
  request: (id, token) => createAction(DELETE_QC_DAYOFF.REQUEST, { id, token }),
  success: (id) => createAction(DELETE_QC_DAYOFF.SUCCESS, { id }),
  failure: (err) => createAction(DELETE_QC_DAYOFF.FAILURE, { err }),
};

const importQCDayoff = {
  request: (file, company_id, year, month, token) =>
    createAction(IMPORT_QC_DAYOFF.REQUEST, { file, company_id, year, month, token }),
  success: (data) => createAction(IMPORT_QC_DAYOFF.SUCCESS, { data }),
  failure: (err) => createAction(IMPORT_QC_DAYOFF.FAILURE, { err }),
};

const downloadQCDayoff = {
  request: (params, token) =>
    createAction(DOWNLOAD_QC_DAYOFF.REQUEST, { params, token }),
  success: (data) => createAction(DOWNLOAD_QC_DAYOFF.SUCCESS, { data }),
  failure: (err) => createAction(DOWNLOAD_QC_DAYOFF.FAILURE, { err }),
};

export {
  loadQcDayoff,
  showQcDayoff,
  createQcDayoff,
  updateQcDayoff,
  deletedQcDayoff,
  importQCDayoff,
  downloadQCDayoff,
  LOAD_QC_DAYOFF,
  SHOW_QC_DAYOFF,
  CREATE_QC_DAYOFF,
  UPDATE_QC_DAYOFF,
  DELETE_QC_DAYOFF,
  IMPORT_QC_DAYOFF,
  DOWNLOAD_QC_DAYOFF,
};
