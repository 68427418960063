import {
  LOAD_CONCRETE_COLLECTOR,
  SHOW_CONCRETE_COLLECTOR,
  CREATE_CONCRETE_COLLECTOR,
  UPDATE_CONCRETE_COLLECTOR,
  DELETE_CONCRETE_COLLECTOR,
} from "./actions";

const msg = {
  success_msg: "",
  err_msg: "",
};

const formdata = {
  id: "",
  title: "",
  first_name: "",
  last_name: "",
  username: "",
  password: "",
  phone_no: "",
  email: "",
  company_name: "",
  remark: "",
  user_id: "",
  division_id: "",
  department_id: [],
  section_id: [],
  plant_id: [],
};

const initialState = {
  lists: [],
  total_rows: 0,
  loading: false,
  shouldReload: false,
  data: formdata,
  ...msg,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CONCRETE_COLLECTOR.REQUEST:
    case SHOW_CONCRETE_COLLECTOR.REQUEST:
      return {
        ...state,
        data: formdata,
        ...msg,
        loading: true,
        shouldReload: false,
      };
    case DELETE_CONCRETE_COLLECTOR.REQUEST:
    case CREATE_CONCRETE_COLLECTOR.REQUEST:
    case UPDATE_CONCRETE_COLLECTOR.REQUEST:
      return {
        ...state,
        ...msg,
        loading: true,
        shouldReload: false,
      };

    case LOAD_CONCRETE_COLLECTOR.SUCCESS:
      return {
        ...state,
        lists: action.payload.data.data,
        total_rows: action.payload.data.total,
        loading: false,
      };

    case SHOW_CONCRETE_COLLECTOR.SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        loading: false,
      };

    case CREATE_CONCRETE_COLLECTOR.SUCCESS:
      return {
        ...state,
        success_msg: "save_success",
        loading: false,
      };

    case UPDATE_CONCRETE_COLLECTOR.SUCCESS:
      return {
        ...state,
        success_msg: "edit_success",
        loading: false,
      };

    case DELETE_CONCRETE_COLLECTOR.SUCCESS:
      return {
        ...state,
        success_msg: "delete_success",
        loading: false,
        shouldReload: true,
      };

    case LOAD_CONCRETE_COLLECTOR.FAILURE:
    case SHOW_CONCRETE_COLLECTOR.FAILURE:
    case CREATE_CONCRETE_COLLECTOR.FAILURE:
    case UPDATE_CONCRETE_COLLECTOR.FAILURE:
    case DELETE_CONCRETE_COLLECTOR.FAILURE:
      return {
        ...state,
        err_msg: action.payload.err,
        loading: false,
        shouldReload: false,
      };

    default:
      return state;
  }
};
