import React from "react";
import { Link } from "react-router-dom";
import { Menu, Tooltip, Drawer } from "antd";

import { translate } from "lib/helper";
import { GroupMenu, ListMenu } from "./ListMenu";

const { SubMenu } = Menu;

const SliderBar = (props) => {
  const {
    collapsed,
    profile: { permissions },
    toggle,
  } = props;

  const shouldRenderMenu = (permissions, key) => {
    let cnt = 0;

    permissions.map((name) => {
      if (
        typeof ListMenu.find(
          (x) => x.group === key && x.matching_key === name.matching_name
        ) !== "undefined"
      ) {
        if (name.can_view !== 0) {
          return cnt++;
        }
      }
      return cnt;
    });

    return cnt > 0 ? true : false;
  };

  return (
    <Drawer
      title={
        <h3
          style={{
            fontSize: "24px",
            color: "white",
            fontWeight: "bold",
          }}
        >
          MENU
        </h3>
      }
      placement="left"
      onClose={toggle}
      visible={!collapsed}
      style={{ position: "absolute" }}
      bodyStyle={{ padding: "0px", border: "0px" }}
      width={322}
      headerStyle={{ backgroundColor: "#29313a" }}
      drawerStyle={{ backgroundColor: "#29313a" }}
    >
      <Menu
        theme="dark"
        mode="inline"
        id="siderbar"
        defaultSelectedKeys={["1"]}
      >
        {GroupMenu.map((group, i) => {
          return (
            shouldRenderMenu(permissions, group.key) && (
              <SubMenu
                key={group.key}
                icon={group.icon}
                title={translate(group.title, "sidebar")}
              >
                {ListMenu.map((menu, i) => {
                  if (menu.group !== group.key) return null;
                  let data = permissions.find(
                    (x) => x.matching_name === menu.matching_key
                  );
                  if (typeof data === "undefined") return null;
                  if (parseInt(data.can_view) === 0) return null;
                  return (
                    <Menu.Item key={menu.title}>
                      <Tooltip title={translate(menu.title, "sidebar")}>
                        <Link to={menu.path}>
                          {menu.module === "report" &&
                            translate(menu.title + "2", "sidebar")}
                          {menu.module !== "report" &&
                            translate(menu.title, "sidebar")}
                        </Link>
                      </Tooltip>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            )
          );
        })}
      </Menu>
    </Drawer>
  );
};

SliderBar.defaultProps = {
  profile: { permissions: [] },
};

export default SliderBar;
