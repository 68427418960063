import { all, put, takeEvery } from "redux-saga/effects";
import { fetcher, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadPhysicalcountMold(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post("mold/getphysicalcountmold", params, token);
    yield put(actions.loadPhysicalcountMold.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

export default function* watchTransferMold() {
  yield all([
    takeEvery(actions.LOAD_PHYSICALCOUNT_MOLD.REQUEST, loadPhysicalcountMold),
 
  ]);
}
