import { createTypes, createAction } from "lib/action";

const LOAD_MOLD = createTypes("loadMold", "load");
const SHOW_MOLD = createTypes("showMold", "show");
const UPDATE_MOLD = createTypes("updateMold", "update");
const DELETE_MOLD = createTypes("deleteMold", "delete");
const IMPORT_MOLD = createTypes("importMold", "import");
const DOWNLOAD_MOLD = createTypes("downloadMold", "download");

const loadMold = {
  request: (params, token) => createAction(LOAD_MOLD.REQUEST, { params, token }),
  success: data => createAction(LOAD_MOLD.SUCCESS, { data }),
  failure: err => createAction(LOAD_MOLD.FAILURE, { err }),
};

const showMold = {
  request: (id, token) => createAction(SHOW_MOLD.REQUEST, { id, token }),
  success: data => createAction(SHOW_MOLD.SUCCESS, { data }),
  failure: err => createAction(SHOW_MOLD.FAILURE, { err }),
};

const updateMold = {
  request: (params, token) => createAction(UPDATE_MOLD.REQUEST, { params, token }),
  success: data => createAction(UPDATE_MOLD.SUCCESS, { data }),
  failure: err => createAction(UPDATE_MOLD.FAILURE, { err }),
};

const deletedMold = {
  request: (id, token) => createAction(DELETE_MOLD.REQUEST, { id, token }),
  success: id => createAction(DELETE_MOLD.SUCCESS,  { id } ),
  failure: err => createAction(DELETE_MOLD.FAILURE, { err }),
};

const importMold = {
    request: (file, user_id, token) => createAction(IMPORT_MOLD.REQUEST, { file, user_id, token }),
    success: data => createAction(IMPORT_MOLD.SUCCESS, { data }),
    failure: err => createAction(IMPORT_MOLD.FAILURE, { err }),
}

const downloadMold = {
    request: (token) => createAction(DOWNLOAD_MOLD.REQUEST, { token }),
    success: data => createAction(DOWNLOAD_MOLD.SUCCESS, { data }),
    failure: err => createAction(DOWNLOAD_MOLD.FAILURE, { err }),
}

export {
  loadMold,
  showMold,
  updateMold,
  deletedMold,
  importMold,
  downloadMold,
  LOAD_MOLD,
  SHOW_MOLD,
  UPDATE_MOLD,
  DELETE_MOLD,
  IMPORT_MOLD,
  DOWNLOAD_MOLD
};
