import { LOAD_CALENDAR_WORKLIST, ASSIGN_WORK_TO_QC } from "./actions";

const msg = {
    success_msg: "",
    err_msg: "",
  };
  
  const initialState = {
    lists: {
        events: [],
        resources:[]
    },
    total_rows: 0,
    loading: false,
    shouldLoad: false,
    ...msg,
  };

  export default (state = initialState, action) => {
    switch (action.type) {
      case LOAD_CALENDAR_WORKLIST.REQUEST:
      case ASSIGN_WORK_TO_QC.REQUEST:
        return {
          ...initialState,
          ...msg,
          loading: true,
          shouldLoad: false,
        };
  
      case LOAD_CALENDAR_WORKLIST.SUCCESS:
        return {
          ...state,
          lists: action.payload.data,
          total_rows: action.payload.data.total,
          loading: false,
        };
  
      case ASSIGN_WORK_TO_QC.SUCCESS:
        return {
          ...state,
          success_msg: "assign_success",
          data: action.payload.data.data,
          loading: false,
          shouldLoad: true
        };
  
      case LOAD_CALENDAR_WORKLIST.FAILURE:
      case ASSIGN_WORK_TO_QC.FAILURE:
        return {
          ...state,
          err_msg: action.payload.err,
          loading: false,
        };
  
      default:
          return state;
    }
  };
  