import { all, put, takeEvery } from "redux-saga/effects";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadWorkCalendarlist(action) {
  try {
    const { params, token, location_visibility } = action.payload;
    const res = yield fetcher.post(
      "worksheet/calendarqc",
      { ...params, location_visibility },
      token
    );
    yield put(actions.loadWorkCalendarlist.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.loadWorkCalendarlist.failure(getErrorMsg(error)));
  }
}

function* assignwork(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post("worksheet/assignqc", params, token);
    yield put(actions.assignwork.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.assignwork.failure(getErrorMsg(error)));
  }
}

export default function* watchQcCalendar() {
  yield all([
    takeEvery(actions.LOAD_CALENDAR_WORKLIST.REQUEST, loadWorkCalendarlist),
    takeEvery(actions.ASSIGN_WORK_TO_QC.REQUEST, assignwork),
  ]);
}
