import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadConcreteCollector(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post("concretecollector", params, token);
    yield put(actions.loadConcreteCollector.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* showConcretetCollector(action) {
  try {
    const { id, token } = action.payload;
    const res = yield fetcher.get(`concretecollector/${id}`, token);
    yield put(actions.showConcretetCollector.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* createConcretetCollector(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post("concretecollector/create", params, token);
    yield put(actions.createConcretetCollector.success(res.data));
    yield put(push("/concrete-collector"));
  } catch (error) {
    handleError(error);
    yield put(actions.createConcretetCollector.failure(getErrorMsg(error)));
  }
}

function* updateConcretetCollector(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post(`concretecollector/update`, params, token);
    yield put(actions.updateConcretetCollector.success(res.data));
    yield put(push("/concretecollector-data"));
  } catch (error) {
    handleError(error);
    yield put(actions.updateConcretetCollector.failure(getErrorMsg(error)));
  }
}

function* deleteConcretetCollector(action) {
  try {
    const { id, token } = action.payload;
    yield fetcher.get(`concretecollector/delete/${id}`, token);
    yield put(actions.deleteConcretetCollector.success(id));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteConcretetCollector.failure(getErrorMsg(error)));
  }
}

export default function* watchConcreteCollector() {
  yield all([
    takeEvery(actions.LOAD_CONCRETE_COLLECTOR.REQUEST, loadConcreteCollector),
    takeEvery(actions.SHOW_CONCRETE_COLLECTOR.REQUEST, showConcretetCollector),
    takeEvery(actions.CREATE_CONCRETE_COLLECTOR.REQUEST, createConcretetCollector),
    takeEvery(actions.UPDATE_CONCRETE_COLLECTOR.REQUEST, updateConcretetCollector),
    takeEvery(actions.DELETE_CONCRETE_COLLECTOR.REQUEST, deleteConcretetCollector),
  ]);
}
