import {
    LOAD_PHYSICALCOUNT_MOLD
} from "./actions";

const msg = {
  success_msg: "",
  err_msg: "",
};

const initialState = {
  lists: [],
  total_rows: 0,
  loading: false,
  shouldReload: false,
  data: [],
  ...msg,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PHYSICALCOUNT_MOLD.REQUEST: 
    case LOAD_PHYSICALCOUNT_MOLD.SUCCESS:
      return {
        ...state,
        lists: action.payload.data.data,
        total_rows: action.payload.data.total,
        loading: false,
      };

    case LOAD_PHYSICALCOUNT_MOLD.FAILURE:
    default:
      return state;
  }
};
