import {
  LOADROUTINE_DATA,
  LOADSITE,
  LOADROUTINEDETAIL,
  CREATE_ROUTINE,
  UPDATE_ROUTINE,
  DELETE_ROUTINE,
  DELETE_ITEM_ROUTINE,
} from "./actions";
const initialState = {
  lists: [],
  details: {
    qc_id: "",
    site_list: [],
    site_list_data: [],
  },
  status: "",
  loading: false,
  sc_msg: "",
  err_msg: "",
};
export default (state = initialState, action) => {
  switch (action.type) {
    case LOADROUTINE_DATA.REQUEST:
    case LOADSITE.REQUEST:
    case LOADROUTINEDETAIL.REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case CREATE_ROUTINE.REQUEST:
    case UPDATE_ROUTINE.REQUEST:
    case DELETE_ROUTINE.REQUEST:
    case DELETE_ITEM_ROUTINE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOADROUTINE_DATA.SUCCESS:
    case LOADSITE.SUCCESS:
      return {
        ...state,
        lists: action.payload.data.data,
        loading: false,
        total: action.payload.data.total,
      };
    case LOADROUTINEDETAIL.SUCCESS:
      return {
        ...state,
        details: action.payload.data,
        loading: false,
      };
    case CREATE_ROUTINE.SUCCESS:
      return {
        ...state,
        status: "success",
        sc_msg: "create success",
        loading: false,
      };
    case UPDATE_ROUTINE.SUCCESS:
      return {
        ...state,
        status: "success",
        sc_msg: "update success",
        loading: false,
      };
    case DELETE_ROUTINE.SUCCESS:
      return {
        ...state,
        status: "success",
        sc_msg: "delete success",
        loading: false,
      };
    case DELETE_ITEM_ROUTINE.SUCCESS:
      return {
        ...state,
        status: "success",
        sc_msg: "delete item success",
        loading: false,
      };
    case CREATE_ROUTINE.FAILURE:
    case DELETE_ROUTINE.FAILURE:
    case DELETE_ITEM_ROUTINE.FAILURE:
    case UPDATE_ROUTINE.FAILURE:
    case LOADSITE.FAILURE:
    case LOADROUTINE_DATA.FAILURE:
      return {
        ...state,
        status: "fail",
        err_msg: action.payload.err,
        loading: false,
      };
    default:
      return state;
  }
};
