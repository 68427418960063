import {
  CHECK_LOGIN,
  LOAD_CREDENTIALS,
  REMOVE_CREDENTIALS,
  removeCredentials
} from "./actions";

const initialState = {
  tokenNoti: null,
  tokenExp: null,
  accessToken: null,
  profile: null,
  done: false,
  status: "",
  msg: "",
  loading: false,
  provinceLists: [],
  amphurLists: [],
  subamphurLists: [],
  path_signup: "",
  check_contact: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CREDENTIALS:
      const {
        payload: { token, tokenExp, profile, done, msg },
      } = action;
      let {
        permissions,
        config,
      } = profile;

      permissions = JSON.parse(permissions || "{}");
      config = JSON.parse(config || "{}");

      return {
        ...initialState,
        tokenExp: tokenExp,
        accessToken: token,
        profile: {
          ...profile,
          permissions,
          config,
        },
        done: done,
        msg: msg,
      };
    case REMOVE_CREDENTIALS:
      removeCredentials();
      return initialState;
    case CHECK_LOGIN.REQUEST: {
      return {
        loading: true,
        status: "",
      };
    }
    case CHECK_LOGIN.SUCCESS: {
      let {
        payload: {
          token,
          tokenPayload,
          permissions,
          vehicle_list,
          location_list,
          vehicle_group_list,
          status,
          msg,
          divisions,
          departments,
          sections,
        },
      } = action;

      let {
        exp,
        config,
      } = tokenPayload;

      permissions = JSON.parse(permissions || "{}");
      config = JSON.parse(config || "{}");
      return {
        accessToken: token,
        tokenExp: exp,
        profile: {
          ...tokenPayload,
          vehicle_visibility: vehicle_list,
          location_visibility: location_list,
          vehicle_group_visibility: vehicle_group_list,
          permissions,
          config,
          divisions,
          departments,
          sections,
        },
        done: true,
        msg: msg,
        status: status,
        loading: false,
      };
    }
    case CHECK_LOGIN.FAILURE: {
      const {
        payload: { status, msg, path_signup },
      } = action;

      return {
        ...initialState,
        msg: msg,
        status: status,
        loading: false,
        path_signup,
      };
    }
    default:
      return state;
  }
};
