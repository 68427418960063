import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Row, Col, Menu, Modal } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  LoginOutlined,
  MenuOutlined,
} from "@ant-design/icons";

import * as authActions from "modules/auth/actions";
import { clearFlashMessage, removeUIState } from "modules/ui/actions";
import { translate } from "lib/helper";
import {
  StyledHearder,
  MenuHeader,
  StyledImageHeader,
} from "styled/common-styled";
import SliderBar from "./SliderBar";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class Header extends Component {
  state = {
    notiCount: 0,
    haveNewNoti: false,
    inputLng: "",
    token: "",
    collapsed: true,
  };

  _isMounted = false;

  onRemoveCredentials = () => {
    this.props.removeCredentials();
    this.props.removeUIState();
  };

  onDismiss = () => {
    this.props.clearFlashMessage();
  };

  componentDidMount() {
    this._isMounted = true;
    let self = this;
    let { tokenExp } = this.props;
    let timeNow = Date.now();

    if (Math.floor(timeNow / 1000) < tokenExp) {
      let diffTime = (tokenExp - Math.floor(timeNow / 1000)) * 1000;
      this.tokenExpTimeout = setTimeout(function () {
        self.modal(
          translate("notification", "header"),
          translate("tokenExpired", "header")
        );
      }, diffTime);
    }

    this.setState({
      inputLng: "th",
    });
  }

  setToken(token) {
    this.setState({
      ...this.state,
      token,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.tokenExpTimeout);
  }

  checkSelectLang = (inputLng) => {
    this.props.changeLang(inputLng);
    if (inputLng === "en") {
      this.setState({
        inputLng: "us",
      });
    } else {
      this.setState({
        inputLng: "th",
      });
    }
  };

  modal = (title, content) => {
    this.onRemoveCredentials();
    Modal.warning({
      title: title,
      okText: "Ok",
      okType: "default",
      onOk() {},
      content: content,
    });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { done } = this.props;

    return (
      <Fragment>
        <StyledHearder>
          <div className="space-between-middle">
            <div style={{ display: "flex", alignItems: "center" }}>
              <StyledImageHeader style={{ width: "200px" }}>
                <img
                  style={{ width: "134px" }}
                  src={"/img/smartquality2-web.png"}
                  alt=""
                />
              </StyledImageHeader>
              <MenuOutlined
                className="trigger"
                type="menu"
                onClick={this.toggle}
                style={{ marginTop: "-10px" }}
              />
            </div>
            <Col sm={16} md={6} lg={6} xl={6}>
              <MenuHeader mode="horizontal" align="right">
                <SubMenu
                  id="sub1"
                  data-cy="user-info"
                  title={
                    <div>
                      <Row align="middle">
                        {" "}
                        <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                          <UserOutlined />
                        </Col>
                      </Row>
                      <Row align="middle">
                        <Col xs={0} sm={24} md={24} lg={24} xl={24}>
                          <UserOutlined />
                          {localStorage.getItem("profile") !== null &&
                            JSON.parse(localStorage.getItem("profile"))
                              .username}
                        </Col>
                      </Row>
                    </div>
                  }
                >
                  <MenuItemGroup title={translate("titleAccount", "header")}>
                    {done ? (
                      <Menu.Item
                        key="menu:1"
                        style={{ paddingLeft: "14px" }}
                        onClick={this.onRemoveCredentials}
                      >
                        <LogoutOutlined />
                        {translate("logout", "header")}
                        <Link data-cy="logout" to="/" />
                      </Menu.Item>
                    ) : (
                      <Menu.Item key="menu:1" style={{ paddingLeft: "14px" }}>
                        <LoginOutlined />
                        {translate("login", "header")}
                        <Link to="/" />
                      </Menu.Item>
                    )}
                  </MenuItemGroup>
                </SubMenu>
              </MenuHeader>
            </Col>
          </div>
        </StyledHearder>

        <SliderBar
          toggle={this.toggle}
          collapsed={this.state.collapsed}
          profile={this.props.profile}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  auth: { accessToken, done, profile, tokenExp },
  ui: { flash, lastestNotificationAt },
}) => ({
  accessToken,
  done,
  profile,
  flash,
  lastestNotificationAt,
  tokenExp,
});
const mapDispatchToProps = {
  removeCredentials: authActions.removeCredentials,
  removeUIState: removeUIState,
  loadCredentials: authActions.loadCredentials,
  clearFlashMessage: clearFlashMessage,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
