import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadQC(action) {
    try {
        const { params, token } = action.payload;
        const res = yield fetcher.post("qc", params, token);
        yield put(actions.loadQC.success(res.data));
    } catch (error) {
        handleError(error);
    }
}

function* showQC(action) {
    try {
        const { id, token } = action.payload;
        const res = yield fetcher.get(`qc/${id}`, token);
        yield put(actions.showQC.success(res.data));
    } catch (error) {
        handleError(error);
    }
}

function* createQC(action) {
    try {
        const { params, token } = action.payload;
        const res = yield fetcher.post("qc/create", params, token);
        yield put(actions.createQC.success(res.data));
        yield put(push("/qc-data"));
    } catch (error) {
        handleError(error);
        yield put(actions.createQC.failure(getErrorMsg(error)));
    }
}

function* updateQC(action) {
    try {
        const { params, token } = action.payload;
        const res = yield fetcher.post(`qc/updateqcraking`, params, token);
        yield put(actions.updateQC.success(res.data));
        yield put(push("/qc-data"));
    } catch (error) {
        handleError(error);
        yield put(actions.updateQC.failure(getErrorMsg(error)));
    }
}

function* deletedQC(action) {
    try {
        const { id, token } = action.payload;
        yield fetcher.get(`qc/delete/${id}`, token);
        yield put(actions.deletedQC.success(id));
    } catch (error) {
        handleError(error);
        yield put(actions.deletedQC.failure(getErrorMsg(error)));
    }
}

function* importQC(action) {
    try {
        const { file, user_id, token } = action.payload;
        const formData = new FormData();
        formData.append("file", file.originFileObj);
        formData.append("user_id", user_id);
        const res = yield fetcher.upload(`qc/import`, formData, token);
        yield put(actions.importQC.success(res.data));
        yield put(push("/qc-data"));
    } catch (error) {
        handleError(error);
        yield put(actions.importQC.failure(getErrorMsg(error)));
    }
}

function* exportQC(action) {
    try {
        const { params, token } = action.payload;
        const res = yield fetcher.post(`qc/export`, params, token);
        yield put(actions.exportQC.success(res.data));
        fetcher.download(res.data.data.replace(/\//g, "."));
    } catch (error) {
        handleError(error);
        yield put(actions.exportQC.failure(getErrorMsg(error)));
    }
}

export default function* watchQC() {
    yield all([
        takeEvery(actions.LOAD_QC.REQUEST, loadQC),
        takeEvery(actions.SHOW_QC.REQUEST, showQC),
        takeEvery(actions.CREATE_QC.REQUEST, createQC),
        takeEvery(actions.UPDATE_QC.REQUEST, updateQC),
        takeEvery(actions.DELETE_QC.REQUEST, deletedQC),
        takeEvery(actions.IMPORT_QC.REQUEST, importQC),
        takeEvery(actions.EXPORT_QC.REQUEST, exportQC),
    ]);
}
