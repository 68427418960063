import { all, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadTransferMold(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post("mold/gettransferconcretemold", params, token);
    yield put(actions.loadTransferMold.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* transferConcreteMold(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post("mold/transferconcretemold", params, token);
    yield put(actions.transferConcreteMold.success(res.data));
    yield put(push("/transfer-formwork"));
  } catch (error) {
    handleError(error);
    yield put(actions.transferConcreteMold.failure(getErrorMsg(error)));
  }
}

export default function* watchTransferMold() {
  yield all([
    takeEvery(actions.LOAD_TRANSFER_MOLD.REQUEST, loadTransferMold),
    takeEvery(actions.TRANSFER_CONCRETE_MOLD.REQUEST, transferConcreteMold),
  ]);
}
