import { all, put, takeEvery } from "redux-saga/effects";
import { fetcher, getErrorMsg, handleError } from "lib/fetch";
import * as actions from "./actions";

function* loadWorklist(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post("worksheet", params, token);
    yield put(actions.loadWorklist.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* showWorklist(action) {
  try {
    const { id, token } = action.payload;
    const res = yield fetcher.get(`worksheet/${id}`, token);
    yield put(actions.showWorklist.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* createWorklist(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post(`worksheet/create`, params, token);
    yield put(actions.createWorklist.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.createWorklist.failure(getErrorMsg(error)));
  }
}

function* updateWorklist(action) {
  const { params, token } = action.payload;

  try {
    const res = yield fetcher.post(`worksheet/update`, params, token);
    yield put(actions.updateWorklist.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.updateWorklist.failure(getErrorMsg(error), params));
  }
}

function* deletedWorklist(action) {
  try {
    const { id, token } = action.payload;
    yield fetcher.get(`worksheet/delete/${id}`, token);
    yield put(actions.deletedWorklist.success(id));
  } catch (error) {
    handleError(error);
    yield put(actions.deletedWorklist.failure(getErrorMsg(error)));
  }
}

function* importWorklist(action) {
  try {
    const { file, company_id, user_id, token } = action.payload;
    const formData = new FormData();
    formData.append("file", file.originFileObj);
    formData.append("company_id", company_id);
    formData.append("user_id", user_id);
    const res = yield fetcher.upload(`worksheet/import`, formData, token);
    yield put(actions.importWorklist.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.importWorklist.failure(getErrorMsg(error)));
  }
}

export default function* watchQcJob() {
  yield all([
    takeEvery(actions.LOAD_WORKLIST.REQUEST, loadWorklist),
    takeEvery(actions.SHOW_WORKLIST.REQUEST, showWorklist),
    takeEvery(actions.CREATE_WORKLIST.REQUEST, createWorklist),
    takeEvery(actions.UPDATE_WORKLIST.REQUEST, updateWorklist),
    takeEvery(actions.DELETE_WORKLIST.REQUEST, deletedWorklist),
    takeEvery(actions.IMPORT_WORKLIST.REQUEST, importWorklist),
  ]);
}
