import {
  LOAD_MOLD,
  SHOW_MOLD,
  UPDATE_MOLD,
  DELETE_MOLD,
  IMPORT_MOLD,
  DOWNLOAD_MOLD,
} from "./actions";

const msg = {
  success_msg: "",
  err_msg: "",
};

const initialState = {
  lists: [],
  total_rows: 0,
  loading: false,
  shouldReload: false,
  data: [
    {
      code: "",
      concrete_mold_id: "",
      concrete_mold_name: "",
      plant_id: "",
      plant_name: "",
      remark: "-",
    },
  ],
  ...msg,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MOLD.REQUEST:
    case SHOW_MOLD.REQUEST:
    case UPDATE_MOLD.REQUEST:
    case DELETE_MOLD.REQUEST:
    case IMPORT_MOLD.REQUEST:
    case DOWNLOAD_MOLD.REQUEST:
      return {
        ...state,
        ...msg,
        loading: true,
        shouldReload: false,
      };

    case LOAD_MOLD.SUCCESS:
      return {
        ...state,
        lists: action.payload.data.data,
        total_rows: action.payload.data.total,
        loading: false,
      };

    case SHOW_MOLD.SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        loading: false,
      };

    case UPDATE_MOLD.SUCCESS:
      return {
        ...state,
        success_msg: "edit_success",
        loading: false,
        shouldReload: true,
      };

    case DELETE_MOLD.SUCCESS:
      return {
        ...state,
        success_msg: "delete_success",
        lists: state.lists.filter((x) => x.id !== action.payload.id),
        loading: false,
        shouldReload: true,
      };

    case IMPORT_MOLD.SUCCESS:
      return {
        ...state,
        success_msg: "import_success",
        loading: false,
        shouldReload: true,
      };

    case DOWNLOAD_MOLD.SUCCESS:
      return {
        ...state,
        success_msg: "export_success",
        loading: false,
      };
    case LOAD_MOLD.FAILURE:
    case SHOW_MOLD.FAILURE:
    case UPDATE_MOLD.FAILURE:
    case DELETE_MOLD.FAILURE:
    case IMPORT_MOLD.FAILURE:
    case DOWNLOAD_MOLD.FAILURE:
      return {
        ...state,
        err_msg: action.payload.err,
        loading: false,
        shouldReload: false,
      };

    default:
      return state;
  }
};
