import { combineReducers } from "redux";

// etc
import auth from "modules/auth/reducer";
import ui from "modules/ui/reducer";

// master data
import permissionMasterDataState from "modules/master-data/permission/reducer";
import usergroupMasterDataState from "modules/master-data/usergroup/reducer";
import userMaster from "modules/master-data/user/reducer";
import concreteCollector from "modules/master-data/concrete-collector/reducer";

import qcMangement from "modules/qc-management/reducer"
import tracking from "modules/tracking/reducer"
import formworkMangement from "modules/formwork/reducer"

export default combineReducers({
  auth,
  ui,
  permissionMasterDataState,
  usergroupMasterDataState,
  userMaster,
  concreteCollector,
  ...formworkMangement,
  ...qcMangement,
  ...tracking
});
