import { all, put, takeEvery } from "redux-saga/effects";
import { fetcher, getErrorMsg, handleError } from 'lib/fetch'
import * as actions from "./actions";

function* loadQcTracking(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post('qc/trackingqc', params, token)
    yield put(actions.loadQcTracking.success(res.data));
  } catch (error) {
    handleError(error)
    yield put(actions.loadQcTracking.failure(getErrorMsg(error)));
  }
}

export default function* watchQcTracking() {
    yield all([
      takeEvery(actions.LOAD_QC_TRACKING.REQUEST, loadQcTracking),
    ]);
  }