import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as actions from "./actions";

function* loadPermission(action) {
  const {
    data: {
      page,
      pageSize,
      searchName,
      companyID,
      filterObj,
      orderBy,
      orderType,
    },
    token,
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/permission`,
      {
        searchName,
        page,
        pageSize,
        companyID,
        filterObj,
        orderBy,
        orderType,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    yield put(actions.loadPermission.success(res.data, page));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadPermission.failure());
  }
}

function* loadPermissionDetail(action) {
  const {
    data: { id },
    token,
  } = action.payload;
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/permission/detail/${id}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    // cb(res.data[0]);
    // console.log(res.data.data);
    yield put(actions.loadPermissionDetail.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.loadPermissionDetail.failure());
  }
}

function* createPermission(action) {
  const {
    data: {
      name,
      matching_name,
      menu_hierarchy,
      description,
      active,
      view_permission,
      add_permission,
      edit_permission,
      delete_permission,
      special_1_permission,
      special_2_permission,
      special_3_permission,
      special_4_permission,
      special_1_name,
      special_2_name,
      special_3_name,
      special_4_name,
      usergroup,
    },
    cb,
    token,
    user_id,
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/permission/create`,
      {
        name,
        matching_name,
        menu_hierarchy,
        description,
        active,
        view_permission,
        add_permission,
        edit_permission,
        delete_permission,
        special_1_permission,
        special_2_permission,
        special_3_permission,
        special_4_permission,
        special_1_name,
        special_2_name,
        special_3_name,
        special_4_name,
        usergroup,
        created_by: user_id,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    cb(res.data);
    yield put(actions.createPermission.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.createPermission.failure());
  }
}

function* updatePermission(action) {
  const {
    data: {
      permission_id,
      name,
      matching_name,
      menu_hierarchy,
      description,
      active,
      view_permission,
      add_permission,
      edit_permission,
      delete_permission,
      special_1_permission,
      special_2_permission,
      special_3_permission,
      special_4_permission,
      special_1_name,
      special_2_name,
      special_3_name,
      special_4_name,
      usergroup,
    },
    cb,
    token,
    user_id
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/permission/update`,
      {
        permission_id,
        name,
        matching_name,
        menu_hierarchy,
        description,
        active,
        view_permission,
        add_permission,
        edit_permission,
        delete_permission,
        special_1_permission,
        special_2_permission,
        special_3_permission,
        special_4_permission,
        special_1_name,
        special_2_name,
        special_3_name,
        special_4_name,
        usergroup,
        updated_by: user_id,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    cb(res.data);

    yield put(actions.updatePermission.success(res.data));
  } catch (err) {
    console.log(err.message);
    yield put(actions.updatePermission.failure());
  }
}

export default function* watchPermissionMasterDataState() {
  yield all([
    takeEvery(actions.CREATE_PERMISSION.REQUEST, createPermission),
    takeEvery(actions.LOAD_PERMISSION.REQUEST, loadPermission),
    takeEvery(actions.LOAD_PERMISSION_DETAIL.REQUEST, loadPermissionDetail),
    takeEvery(actions.UPDATE_PERMISSION.REQUEST, updatePermission),
  ]);
}

export {
  createPermission,
  loadPermission,
  loadPermissionDetail,
  updatePermission,
};
