import React, { Component } from "react";
import { Route } from "react-router-dom";
import loadable from "@loadable/component";

import Login from "modules/auth/components/Login";

const AsyncPage = loadable((props) => {
  switch (props.module) {
    case "master-data":
      return import(
        `modules/master-data/${props.sub_module}/components/Overview`
      );
    case "qc-management":
      return import(
        `modules/qc-management/${props.sub_module}/components/Overview`
      );
    case "tracking":
      return import(`modules/tracking/${props.sub_module}/components/Overview`);
    case "formwork":
      return import(`modules/formwork/${props.sub_module}/components/Overview`);
    case "report":
      return import(`modules/report/${props.sub_module}/components/Overview`);
    default:
      break;
  }
});

class PrivateRoute extends Component {
  checkRout = () => {
    const {
      value: { module, sub_module, path },
      done,
      value,
    } = this.props;
    if (!window.location.pathname.match(/calendar/g)) {
      localStorage.removeItem("division_id");
      localStorage.removeItem("department_id");
      localStorage.removeItem("section_id");
      localStorage.removeItem("date_now");
      localStorage.removeItem("date_start");
      localStorage.removeItem("date_end");
      localStorage.removeItem("type");
      localStorage.removeItem("pick_date");
      localStorage.removeItem("duration_date");
      localStorage.removeItem("duration_week");
      localStorage.removeItem("plant_id");
    }
    if (done === true) {
      return (
        <Route
          component={(props) => (
            <AsyncPage
              module={module}
              sub_module={sub_module}
              value={value}
              {...props}
            />
          )}
          path={path}
        />
      );
    } else {
      return <Route exact path="/" component={Login} />;
    }
  };

  render() {
    return this.checkRout();
  }
}

export default PrivateRoute;
