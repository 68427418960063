import { all, put, takeEvery } from "redux-saga/effects";
import { fetcher, handleError, getErrorMsg } from "lib/fetch";
import * as actions from "./actions";

function* loadQcRoutineData(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post("qcroutine", params, token);
    yield put(actions.loadQcRoutineData.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* loadSite(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.get("getsitefleetlink", params, token);
    yield put(actions.loadSite.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* loadRoutineDetail(action) {
  try {
    const { id, token } = action.payload;
    const res = yield fetcher.get(`qcroutine/${id}`, token);
    yield put(actions.loadRoutineDetail.success(res.data));
  } catch (error) {
    handleError(error);
  }
}

function* createRoutine(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post(`qcroutine/create`, params, token);
    yield put(actions.createRoutine.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.createRoutine.failure(getErrorMsg(error)));
  }
  action.payload.callback();
}

function* updateRoutine(action) {
  const { params, token } = action.payload;

  try {
    const res = yield fetcher.post(`qcroutine/update`, params, token);
    yield put(actions.updateRoutine.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.updateRoutine.failure(getErrorMsg(error), params));
  }
  action.payload.callback();
}

function* deleteRoutine(action) {
  try {
    const { id, token } = action.payload;
    yield fetcher.delete(`qcroutine/delete/${id}`, token);
    yield put(actions.deleteRoutine.success(id));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteRoutine.failure(getErrorMsg(error)));
  }
  action.payload.callback();
}

function* deleteItemRoutine(action) {
  try {
    const { params, token } = action.payload;
    const res = yield fetcher.post(`qcroutine/deleteqcsite`, params, token);
    yield put(actions.deleteItemRoutine.success(res.data));
  } catch (error) {
    handleError(error);
    yield put(actions.deleteItemRoutine.failure(getErrorMsg(error)));
  }
  action.payload.callback();
}

export default function* watchQcRoutine() {
  yield all([
    takeEvery(actions.LOADROUTINE_DATA.REQUEST, loadQcRoutineData),
    takeEvery(actions.LOADSITE.REQUEST, loadSite),
    takeEvery(actions.LOADROUTINEDETAIL.REQUEST, loadRoutineDetail),
    takeEvery(actions.CREATE_ROUTINE.REQUEST, createRoutine),
    takeEvery(actions.UPDATE_ROUTINE.REQUEST, updateRoutine),
    takeEvery(actions.DELETE_ROUTINE.REQUEST, deleteRoutine),
    takeEvery(actions.DELETE_ITEM_ROUTINE.REQUEST, deleteItemRoutine),
  ]);
}
