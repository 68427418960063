import { createTypes, createAction } from "lib/action";

const LOAD_CALENDAR_WORKLIST = createTypes("loadWorkCalendarlist", "load");
const ASSIGN_WORK_TO_QC = createTypes("assignwork", "assign");

const loadWorkCalendarlist = {
  request: (params, token, location_visibility) =>
    createAction(LOAD_CALENDAR_WORKLIST.REQUEST, {
      params,
      token,
      location_visibility,
    }),
  success: (data) => createAction(LOAD_CALENDAR_WORKLIST.SUCCESS, { data }),
  failure: (err) => createAction(LOAD_CALENDAR_WORKLIST.FAILURE, { err }),
};

const assignwork = {
  request: (params, token) =>
    createAction(ASSIGN_WORK_TO_QC.REQUEST, { params, token }),
  success: (data) => createAction(ASSIGN_WORK_TO_QC.SUCCESS, { data }),
  failure: (err) => createAction(ASSIGN_WORK_TO_QC.FAILURE, { err }),
};

export {
  loadWorkCalendarlist,
  assignwork,
  LOAD_CALENDAR_WORKLIST,
  ASSIGN_WORK_TO_QC,
};
