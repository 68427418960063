import {
  LOAD_QC_DAYOFF,
  SHOW_QC_DAYOFF,
  CREATE_QC_DAYOFF,
  UPDATE_QC_DAYOFF,
  DELETE_QC_DAYOFF,
  IMPORT_QC_DAYOFF,
  DOWNLOAD_QC_DAYOFF,
} from "./actions";

const msg = {
  success_msg: "",
  err_msg: "",
};

const formData = {
  id: "",
  qc_id: "",
  username: "",
  code: "",
  firstname: "",
  lastname: "",
  dayoff: "",
  date_start_at: null,
  date_end_at: null,
  remark: null,
  dayoff_id: "",
};

const initialState = {
  lists: [],
  total_rows: 0,
  loading: false,
  data: formData,
  shouldReload: false,
  ...msg,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_QC_DAYOFF.REQUEST:
    case UPDATE_QC_DAYOFF.REQUEST:
    case DELETE_QC_DAYOFF.REQUEST:
    case DOWNLOAD_QC_DAYOFF.REQUEST:
      return {
        ...state,
        ...msg,
        loading: true,
        shouldReload: false,
      };
    case LOAD_QC_DAYOFF.REQUEST:
    case SHOW_QC_DAYOFF.REQUEST:
      return {
        ...state,
        data: formData,
        ...msg,
        loading: true,
      };
    case IMPORT_QC_DAYOFF.REQUEST:
      return {
        ...state,
        ...msg,
        loading: true,
        shouldReload: false,
      };

    case LOAD_QC_DAYOFF.SUCCESS:
      return {
        ...state,
        lists: action.payload.data.data,
        total_rows: action.payload.data.total,
        loading: false,
      };

    case SHOW_QC_DAYOFF.SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        loading: false,
      };

    case CREATE_QC_DAYOFF.SUCCESS:
      return {
        ...state,
        success_msg: "save_success",
        loading: false,
      };

    case UPDATE_QC_DAYOFF.SUCCESS:
      return {
        ...state,
        success_msg: "edit_success",
        loading: false,
      };

    case DELETE_QC_DAYOFF.SUCCESS:
      return {
        ...state,
        success_msg: "delete_success",
        lists: state.lists.filter((x) => x.id !== action.payload.id),
        shouldReload: true,
        loading: false,
      };

    case IMPORT_QC_DAYOFF.SUCCESS:
      return {
        ...state,
        success_msg: "import_success",
        loading: false,
        shouldReload: true,
      };

    case DOWNLOAD_QC_DAYOFF.SUCCESS:
      return {
        ...state,
        success_msg: "export_success",
        loading: false,
      };
    case LOAD_QC_DAYOFF.FAILURE:
    case SHOW_QC_DAYOFF.FAILURE:
    case CREATE_QC_DAYOFF.FAILURE:
    case UPDATE_QC_DAYOFF.FAILURE:
    case DELETE_QC_DAYOFF.FAILURE:
    case IMPORT_QC_DAYOFF.FAILURE:
    case DOWNLOAD_QC_DAYOFF.FAILURE:
      return {
        ...state,
        err_msg: action.payload.err,
        loading: false,
        shouldReload: false,
      };

    default:
      return state;
  }
};
