import React from "react";
import * as moment from "moment";
import history from "./history";
import LangContext, { i18n } from "modules/shared/context/langContext";
import {
  Tooltip,
} from "antd";

export const autoGenKey = (perfix) =>
  `${perfix}-${Math.random()
    .toString(36)
    .substring(7)}-${Math.ceil(Math.random() * 1000)}}`;

export const checkEmptyJson = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const checkUnauthorized = (fn, action) => {
  console.log(action);
  history.push("/");
  return fn;
};

export const checkPermissions = (data, matching_name, actions) => {
  let action = true;

  let permissions = data.filter(function (ele) {
    return ele.matching_name === matching_name && parseInt(ele[actions]) === 1;
  });

  if (permissions.length === 0) {
    action = false;
  } else {
    action = true;
  }

  return action;
};

export const hasPermission = (permissions, matchingName, actionName) => {
  let canAccess = permissions.find((ele) => {
    return ele.matching_name === matchingName && parseInt(ele[actionName]) === 1;
  });

  if (matchingName === "") {
    canAccess = true;
  } else if (typeof canAccess == "undefined") {
    canAccess = false;
  } else {
    canAccess = true;
  }

  return canAccess;
};

export const helper_changeNumberStatusToTextStatus = (
  number,
  arrKeyWithValue
) => arrKeyWithValue[number.toString()];

export const helper_convertDateformat = (
  dateStr,
  sourceFormat,
  resultFormat
) => {
  let result = "";
  if (dateStr === null) return dateStr;
  if (dateStr.length > 4 && dateStr !== "")
    result = moment(dateStr, sourceFormat).format(resultFormat);
  else if (dateStr === "now") result = "now";
  return result;
};

export const isNumber = (n) => {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
};

export const numberFormat = (number, decimal = 2) => {
  if (typeof number !== "string" && typeof number !== "number") return;
  if (number === "" || isNaN(number)) number = 0;
  return parseFloat(removeComma(number))
    .toFixed(decimal)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const removeComma = (number) => {
  return number.toString().replace(/,/g, "");
};

export const translate = (key, cateKey, lang) => {
  if (typeof lang !== "undefined") {
    return i18n[lang][cateKey][key];
  } else {
    return (
      <LangContext.Consumer>{(val) => val[cateKey][key]}</LangContext.Consumer>
    );
  }
};

export const updateObjectInArray = (array, action, key) => {
  return array.map((item) => {
    if (item[key] !== action[key]) {
      // This isn't the item we care about - keep it as-is
      return item
    }

    let newitem = Object.assign({}, item, action)
    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...newitem
    }
  })
}

export const isEmptyString = value => {
  if (typeof value === "undefined") return true
  return value === null || value === "" || value === "null" || value === "undefined"
}

export const changeToBoolean = value => {
  if (typeof value === "boolean") return value
  if (parseInt(value) === 0) return false
  else if (parseInt(value) === 1) return true
  return false
}

export const dummyRequest = ({ file, onSuccess }) => {
  var reader = new window.FileReader();
  reader.readAsDataURL(file);
  reader.onload = (...args) => {
    onSuccess("done", file);
  };
};

export const replaceSpacialChar = (str) => {
  return str.replace(/[^\w\s|\u0E00-\u0E7F]/gi, "");
}

export const generateQcRankingBadge = (topic, abbr, level, size = "normal") => {
  let img = {}
  switch (level) {
    case "B":
      img = {
        "level": "Bronze",
        "src": "/img/bronze.png",
        "topic": topic,
        "abbr": abbr,
      }
      break
    case "S":
      img = {
        "level": "Silver",
        "src": "/img/silver.png",
        "topic": topic,
        "abbr": abbr,
      }
      break
    case "G":
      img = {
        "level": "Gold",
        "src": "/img/gold.png",
        "topic": topic,
        "abbr": abbr,
      }
      break
    default:
      img = {
        "src": "",
        "topic": "",
        "abbr": "",
      }
      break
  }


  if (img.src === "") {
    return ""
  }

  let containerCls = size === "small" ? "ranking-container-small" : "ranking-container"
  let imgCls = size === "small" ? "ranking-container-img-small" : "ranking-container-img"
  let textCls = size === "small" ? "ranking-container-text-small" : "ranking-container-text"

  return <div className={containerCls} key={autoGenKey("ranking")}>
    <Tooltip placement="left" title={`${img.level}: ${img.topic}`}>
      <img src={img.src} className={imgCls} alt={img.topic} />
      <div className={textCls}>{img.abbr}</div>
    </Tooltip>
  </div>
}