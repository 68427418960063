import { createTypes, createAction } from "lib/action";

const LOAD_QC_TRACKING = createTypes("loadQcTracking", "load");

const loadQcTracking = {
    request: (params, token) => createAction(LOAD_QC_TRACKING.REQUEST, { params, token }),
    success: data => createAction(LOAD_QC_TRACKING.SUCCESS, { data }),
    failure: err => createAction(LOAD_QC_TRACKING.FAILURE, { err }),
  };

  export {
    loadQcTracking,
    LOAD_QC_TRACKING,
  };
  