import { createTypes, createAction } from "lib/action";

const LOADROUTINE_DATA = createTypes("loadQcRoutineData", "load");
const LOADSITE = createTypes("loadSite", "load");
const LOADROUTINEDETAIL = createTypes("loadRoutineDetail", "load");
const CREATE_ROUTINE = createTypes("createRoutine", "create");
const UPDATE_ROUTINE = createTypes("updateRoutine", "update");
const DELETE_ROUTINE = createTypes("deleteRoutine", "delete");
const DELETE_ITEM_ROUTINE = createTypes("deleteItemRoutine", "deleteItem");

const loadQcRoutineData = {
  request: (params, token) =>
    createAction(LOADROUTINE_DATA.REQUEST, { params, token }),
  success: (data) => createAction(LOADROUTINE_DATA.SUCCESS, { data }),
  failure: (err) => createAction(LOADROUTINE_DATA.FAILURE, { err }),
};

const loadSite = {
  request: (params, token) => createAction(LOADSITE.REQUEST, { params, token }),
  success: (data) => createAction(LOADSITE.SUCCESS, { data }),
  failure: (err) => createAction(LOADSITE.FAILURE, { err }),
};

const loadRoutineDetail = {
  request: (id, token) =>
    createAction(LOADROUTINEDETAIL.REQUEST, { id, token }),
  success: (data) => createAction(LOADROUTINEDETAIL.SUCCESS, { data }),
  failure: (err) => createAction(LOADROUTINEDETAIL.FAILURE, { err }),
};

const createRoutine = {
  request: (params, token, callback) =>
    createAction(CREATE_ROUTINE.REQUEST, { params, token, callback }),
  success: (data) => createAction(CREATE_ROUTINE.SUCCESS, { data }),
  failure: (err) => createAction(CREATE_ROUTINE.FAILURE, { err }),
};

const updateRoutine = {
  request: (params, token, callback) =>
    createAction(UPDATE_ROUTINE.REQUEST, { params, token, callback }),
  success: (data) => createAction(UPDATE_ROUTINE.SUCCESS, { data }),
  failure: (err) => createAction(UPDATE_ROUTINE.FAILURE, { err }),
};

const deleteRoutine = {
  request: (id, token, callback) =>
    createAction(DELETE_ROUTINE.REQUEST, { id, token, callback }),
  success: (data) => createAction(DELETE_ROUTINE.SUCCESS, { data }),
  failure: (err) => createAction(DELETE_ROUTINE.FAILURE, { err }),
};

const deleteItemRoutine = {
  request: (params, token, callback) =>
    createAction(DELETE_ITEM_ROUTINE.REQUEST, { params, token, callback }),
  success: (data) => createAction(DELETE_ITEM_ROUTINE.SUCCESS, { data }),
  failure: (err) => createAction(DELETE_ITEM_ROUTINE.FAILURE, { err }),
};

export {
  loadQcRoutineData,
  LOADROUTINE_DATA,
  loadSite,
  LOADSITE,
  loadRoutineDetail,
  LOADROUTINEDETAIL,
  createRoutine,
  CREATE_ROUTINE,
  updateRoutine,
  UPDATE_ROUTINE,
  deleteRoutine,
  DELETE_ROUTINE,
  deleteItemRoutine,
  DELETE_ITEM_ROUTINE,
};
